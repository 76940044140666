.workflowView {
  &__noData {
    text-align: center;
    margin-top: 10px;
  }

  &__monthlyViewContainer {
    display: flex;
    flex-direction: column;
  }

  &__calendar {
    margin-top: 10px;
    flex: 1 1 auto;
  }
}

.resultsBalanceSummary {
  &__content {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;

    .recordOfQualityResultPricing__value {
      font-size: 13px;
    }

    .span {
      white-space: nowrap;
    }
  }
}

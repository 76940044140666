.systemBalanceTableContainer {
  background: #ffffff;
  flex-grow: 2;
}

.systemBalanceTable {
  min-height: 400px;

  & &__name {
    white-space: normal;
    line-height: 1.25;
  }

  &__row {
    display: flex;
    flex-wrap: nowrap;

    &__value {
      width: 95%;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
    }

    &__unit {
      margin-left: 2px;
      text-align: right;
    }
  }

  &__totalContainer {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid #ddd;
    padding-top: 8px;
    margin-top: -5px;

    .summaryInformation {
      padding: 0;
      padding-right: 10px;
    }
  }
}

.systemBalanceTable .ReactVirtualized__Table__row {
  border: none;
}

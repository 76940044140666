@import "constants/index.js";

.recordOfQualitySelectionTable {
  position: relative;
  min-height: 350px;

  &__container {
    height: 350px;
  }

  &__noData {
    text-align: center;
  }

  &__invalidSample {
    background-color: $disabledRowColour;
  }

  &__measurement {
    display: block;
    text-align: right;
    cursor: default;
  }

  &__warningIcon {
    color: $alarmColour;
  }

  &__tooltip {
    .tooltip-inner {
      max-width: 200px;
    }
    & .recordOfQualitySelectionTable {
      &__alarms {
        margin-top: 8px;

        .recordOfQualitySelectionTable__sectionTitle {
          color: #fff;
          margin: 0;
          margin-bottom: 7px;
          font-weight: bold;
          text-transform: capitalize;
          text-align: left;
        }

        ul {
          margin: 0;
          margin-bottom: 7px;
          padding-left: 18px; // bullet point needs 15px to center
          text-align: left;
          white-space: initial;
          word-break: break-word;
          line-height: 1.4;
          list-style: none;
          // creating a white bullet
          li::before {
            content: "\2022"; // bullet unicode
            color: #fff;
            font-size: 20px;
            vertical-align: middle;
            font-weight: bold;
            display: inline-block;
            width: 12px; // indentation of first line
            margin-left: -15px; // indentation of other lines
            line-height: 0;
          }
        }
      }
    }
  }

  &__checkboxContainer {
    display: flex;
    flex-direction: row;

    .checkbox {
      flex-grow: 2;
      // removes outline if on focus
      & input[type="checkbox"]:focus:after {
        border-color: #666;
      }

      & input[type="checkbox"]:checked:after {
        background-color: var(--colour-success);
        border-color: var(--colour-success);
      }
      input:disabled {
        cursor: not-allowed;
      }
    }
  }

  &__measurement {
    text-align: right;
    font-variant-numeric: tabular-nums;
  }

  .ReactVirtualized__Table__headerColumn {
    text-transform: none;
  }
}

.container {
  display: grid;
  gap: 16px;
  grid-template-columns: 100%;
  grid-template-rows: auto;

  @media (min-width: 1280px) {
    grid-template-columns: 65% calc(35% - 16px);
    grid-template-rows: 1fr;
  }
}

$alphaShadow: 0.26;

.mapTooltipContainer {
  display: flex;
  flex-direction: column;
  position: absolute;
  top: 0px;
  left: -1000px;
  pointer-events: all;
  background-color: transparent;
  z-index: 2;
  opacity: 0;
}

.node {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, $alphaShadow);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, $alphaShadow);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, $alphaShadow);
  background-color: #75838b;
  border-radius: 50%;
  border: 1px solid white;
  width: 24px;
  height: 24px;
  position: relative;
}

.nodeContainer {
  display: flex;
  position: relative;
  justify-content: center;
  align-items: center;
  background-color: transparent;
  width: 175px;
  margin-top: 4px;
}

.message {
  box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, $alphaShadow);
  -webkit-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, $alphaShadow);
  -moz-box-shadow: 0px 1px 8px 0px rgba(0, 0, 0, $alphaShadow);
  background-color: white;
  padding: 12px 16px;
  width: 175px;
  border-radius: 6px;
  line-height: 20px;
}

.facilityName {
  white-space: pre-wrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.mapTooltipLabelContainer {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.mapTooltipLabelMassRow {
  border-radius: 6px;
  background-color: var(--colour-white);
  display: flex;
  justify-content: flex-start;
}

.mapTooltipLabel {
  color: var(--colour-text-600);
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
}

.mapTooltipName {
  color: var(--colour-text-600);
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-weight: 400;
  padding: 0px;
  margin: 0px;
  margin-left: 8;
}

.mapTooltipMass {
  color: var(--colour-text-600);
  font-family: "IBM Plex Sans";
  font-size: 14px;
  font-weight: 700;
  padding: 0px;
  margin: 0px;
  margin-left: 8px;
}

.mapTooltipMassSkeleton {
  width: 80px;
  height: 8px;
  padding: 0px;
  margin-left: 8px;
  margin-top: 4px;
  margin-bottom: 0px;
}

.recordOfQualityModalFooter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  padding-top: 10px;

  & .action {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }

    & .checkboxButton {
      .checkbox {
        margin: 0;
        & input[type="checkbox"] {
          margin-right: 10px;

          // removes outline if on focus
          &:focus:after {
            border-color: #666;
          }

          &:checked:after {
            background-color: #008464;
            border-color: #008464;
          }
        }
      }
    }
  }
}

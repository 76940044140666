@import "constants/index.js";

.adjustmentFactorsCell {
  &__tooltip {
    ul {
      padding-left: 0px;
    }
  }

  &__title {
    cursor: default;
  }
}

.profileForm {
  margin-top: 15px;
}

.profileForm .siteList {
  height: 100px;
}

.profileForm .timezone-picker {
  width: 100%;
}

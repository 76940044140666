.volumeOverview {
  position: relative;

  > * + * {
    margin-top: 20px;
  }

  &__actionRow {
    .dateRangeSelectorContainer {
      border: 1px solid #ddd;
      border-radius: 3px;
      color: #666;
      height: 30px;
      padding: 3px 10px;
    }
  }

  &__chart {
    &Container {
      height: 400px;
    }
  }

  &__table {
    position: relative;
  }

  .componentLoader {
    margin-top: 0;
  }
}

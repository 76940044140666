.balancesAnalyze {
  &__actions {
    height: 35px;
    margin-bottom: 15px;

    .dateRangeSelectorContainer {
      border: 1px solid #ddd;
      padding: 3px 10px;
      color: #666;
      border-radius: 3px;
      background-color: #fff;
    }
  }
}

.comment-panel-content {
  padding: 0;

  &.no-overflow {
    overflow: hidden;
  }
}

.comment-panel-task-accordion {
  [id*="header"] {
    padding: 4px 8px;
    background-color: var(--colour-surface-50);
    border-top: 1px solid var(--colour-surface-200);
    margin: 0;
    padding: 4px 16px 4px 8px;
    gap: 8px;
  }

  .comment-panel-task-title {
    margin-bottom: 0;
    color: var(--colour-text-600);
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
    letter-spacing: 0.14px;
  }

  [role="region"] {
    margin: 0;
  }
}

.comments-empty-state {
  display: block;
  padding: 8px 0px 0px 0px;
}

.comment-panel-footer {
  display: flex;
  height: 300px;
  background-color: var(--colour-surface-50);
  padding: 8px 16px 16px 16px;
  border-top: 1px solid var(--colour-surface-200);
}

.play-flash-animation {
  animation: flash 1s ease-in-out;
}

@keyframes flash {
  from {
    background-color: var(--colour-primary-100);
  }
  to {
    background-color: initial;
  }
}

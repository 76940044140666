.pills-container {
  display: flex;
  flex-direction: row;
  gap: 8px;
  margin-bottom: 16px;
  flex-wrap: wrap;
}

.title {
  font-size: 14px;
  color: var(--colour-text-600, #4d6670);
  font-weight: 400;
  line-height: 18px;
  margin-bottom: 4px;
}

.description {
  font-size: 14px;
  color: var(--colour-text-800);
  font-style: normal;
  font-weight: 600;
  line-height: 18px;
  margin-bottom: 16px;
}

.container {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.leftContainer {
  flex-grow: 0;

  & > * {
    margin: 0;
  }
}

.rightContainer {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > * {
    margin: 0;
  }
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;

  & > * {
    margin: 0;
    flex: 1;
  }
}

.banner {
  margin-bottom: 12px;
}

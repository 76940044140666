.recordOfQualityConfirmationModal {
  & &__notes {
    color: #e51c23;
  }

  .modal-header {
    font-size: 15px;
    font-weight: bold;
  }
}

.container {
  width: 321px;
  display: block;
  background-color: #f3f4f6;
  margin: 0 0 0 20px;
}

.volume-container {
  background-color: #e7ebee;
  padding: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.volume {
  font-size: 40px;
  font-weight: 600;
  margin: 0 0 10px 0;
  position: relative;

  > .supertext {
    font-size: 20px;
    font-weight: 400;
  }
}

.subtext {
  margin: 0;
  font-size: 14px;
  font-weight: 400;
}

.datalist {
  padding: 0 36px;
  margin: 0;
}

.datalist-item {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 20px 0;

  &:not(:last-child) {
    border-bottom: 1px solid #e6eaec;
  }
}

.term {
  font-weight: normal;

  &::after {
    content: ":";
  }
}

.data {
  font-weight: bold;
  text-align: right;
}

.formContainer {
  width: 272px;
}

.description {
  font-size: 16px;
  font-weight: 400;
  padding-bottom: 32px;
  margin-bottom: 0;
}

.horizontalRule {
  max-width: 272px;
  margin-left: 0;
}

.basicInfoContainer {
  max-width: 244px;
}

.streamSelectionContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.streamTableContainer {
  flex: 1;
}

.flowEquipmentSelectionTablePanelContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.flowEquipmentSelectionTablePanelMetricTiles {
  min-width: 400px;
}

.downstreamSelectionContainer {
  min-width: 400px;
}

.connectionsPanelsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;

  & :first-child {
    flex: 1;
  }
}

.selectedAssetsPanelContainer {
  width: 400px;
}

.horizontalRule {
  max-width: 272px;
  margin-left: 0;
}

.imageCanvas {
  position: relative;
}

.imageCanvas__backgroundImage,
.imageCanvas__tubeBlockingLayer {
  pointer-events: none;
}

.imageCanvas__backgroundImage,
.imageCanvas__drawingLayer,
.imageCanvas__tubeBlockingLayer,
.isLoadingNotice {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}

.isLoadingNotice {
  background-color: hsl(0, 0%, 100%);
  font-size: 2em;
  text-align: center;
}

.monthlyView__popup {
  position: fixed;
  border-radius: 8px;
  box-shadow: 0 1px 25px rgba(0, 0, 0, 0.35);
  overflow-y: auto;
  z-index: var(--zindex-popover);
}

.monthlyView__popupBackground {
  background-color: #ddd;
  position: fixed;
  width: 100%;
  height: 100%;
  left: auto;
  top: 0;
}

.monthlyView__popupCloseButton {
  color: #797979;
  box-shadow: none;
  font-size: 18px;
  right: 14px;
  padding: 5px;
  position: absolute;
  top: 9px;
}

@import "constants/index.js";

.analyzePanel {
  min-height: 300px;

  .rv-xy-plot {
    cursor: crosshair;
    margin-top: 40px;
  }

  .rv-hint__content {
    background-color: rgba(0, 0, 0, 0.8);
  }

  .noDataMessage {
    margin-top: 60px;
    text-align: center;
  }

  &__selector {
    display: inline-block;
  }

  &__noBorder {
    display: inline-block;
    margin-right: 10px;

    .dropdown {
      display: inline-block;

      button {
        border: none;
        display: inline-block;
        padding: 0 5px 0 5px;
        text-transform: capitalize;
      }
    }
  }

  &__divider {
    border-right: 1px solid $borderColour;
    display: inline-block;
    height: 19px;
    vertical-align: middle;
  }

  &__statisticPanelContainer {
    position: absolute;
    top: 0;
    z-index: 1;
  }

  &__row {
    margin-left: 0px;
    margin-right: 0px;
  }
}

.analyzeHeader {
  &__bookmarkButton {
    color: #ffc107;
    display: inline;
    margin-left: 10px;
  }

  &__closeButton {
    color: var(--light-text-colour);
    background-color: #ffffff;
    box-shadow: none;
    margin-top: -15px;
    font-size: 20px;
  }
}

.analyticsSelectorRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

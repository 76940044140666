.container {
  padding: 3px 4px 3px;
  border-radius: 20px;
  display: inline-flex;
  flex-direction: row;
  align-items: center;
  flex-grow: 0;
  min-height: 24px;
  background-color: var(--colour-surface-100);
}

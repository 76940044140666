.container {
  display: inline-flex;
  align-items: center;
}

.content-container {
  padding: 0;
  width: auto;
}

.preview {
  margin: 0;
}

.preview-container {
  padding: 12px;
}

.preview-details {
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  .file-name {
    margin: 0;
    color: var(--colour-text-800, #1b4254);
    font-size: 14px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 0.14px;
    display: inline-flex;
    align-items: center;
    gap: 4px;

    &.has-error {
      color: var(--colour-error);
    }
  }
}

.preview-trigger {
  &[data-state="open"] {
    background: none;

    & [data-content] {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

.preview-viewport {
  width: 100%;
  max-width: 400px;
  max-height: 600px;
  margin-top: 12px;
}

.image-preview {
  aspect-ratio: 1 / 1;
  object-fit: contain;
  height: 100%;
  width: 100%;
}

@import "constants/index.js";

.recordOfQualitySampleSelections {
  &__section {
    margin-top: 20px;

    .sidePanelSection__subHeader {
      margin-bottom: 15px;
    }

    .collapse,
    .collapsing {
      > * + * {
        margin-top: 10px;
      }
    }
  }

  &__subHeader {
    display: inline-block;
  }

  &__badge {
    background-color: $validereColour;
    margin-bottom: 3px;
  }

  &__tooltip {
    transform: translateX(-60px);
    .tooltip-inner {
      max-width: 250px;
    }
  }
}

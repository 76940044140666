.items {
  display: flex;
  flex-direction: column;
  gap: 16px;

  & > * {
    flex-grow: 1;
  }

  @media (min-width: 1280px) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}

.attachment-name-and-icon {
  margin-top: 8px;
  display: flex;
  align-items: center;
  gap: 8px;
}

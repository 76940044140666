.testDetailModal__body {
  padding: 0;
}

.testAnalysis {
  overflow-y: auto;
  overflow-x: hidden;

  &__overview {
    display: flex;
    flex-direction: row;
    padding-top: 0;

    .instrumentName {
      text-overflow: ellipsis;
      overflow: hidden;
      width: 180px;
      white-space: nowrap;
    }
  }

  &__downloadableReports {
    margin-bottom: 15px;
  }

  .summaryInformation {
    margin-right: 20px;
  }

  .measurementTable {
    height: 470px;
    border: 1px solid #ddd;
  }
}

.recordOfQualityResults {
  overflow-y: auto;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__container {
    position: relative;
  }

  &__balanceContainer {
    position: relative;
    flex: 1 1;
  }

  .panel {
    margin-bottom: 15px;
  }

  &__section {
    & > .sidePanelSection__subHeader {
      margin-bottom: 15px;
    }
  }
}

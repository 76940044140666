@import "constants/mixins";

.title {
  @include fontStyles(headline, sm, true);
}

.list {
  list-style: none;
  padding: 0;
}

.item {
  margin-bottom: 4px;

  &:last-child {
    & .value {
      margin-bottom: 0;
    }
  }
}

.label {
  color: var(--colour-text-400);
  font-size: 13px;
}

.value {
  font-weight: 600;
  color: var(--colour-text-800);
}

.card {
  width: 100%;
  height: 200px;
}

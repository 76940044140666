.workflowPreview {
  background-color: var(--colour-white);
  border: 1px solid var(--borderColour);
  height: auto;
  padding: 10px;
  display: inline-block;
  font-size: 11px;
  margin-bottom: 20px;
  position: sticky;
  top: 0;
}

.workflowPreview__title {
  margin-bottom: 15px;
}

.workflowPreview__messageContainer {
  margin-left: 15px;
  max-width: 200px;
}

.workflowPreview__messageContainer .title {
  font-weight: bold;
  text-decoration: underline;
}

.workflowPreview__messageContainer p {
  margin-bottom: 20px;
  padding-right: 12px;
  line-height: 1.1;
}

.workflowPreview .DayPicker-Months {
  height: 230px;
}

.workflowPreview .DayPicker-Caption div {
  font-size: 1.3rem;
  font-weight: 600;
  margin-left: 2px;
  margin-top: -4px;
}

.workflowPreview .DayPicker-Day {
  padding: 0.3rem;
  outline: 0;
}

.workflowPreview .DayPicker-Caption div {
  font-weight: normal;
}

.workflowPreview .DayPicker .dayCell__date {
  padding: 0 4px;
}

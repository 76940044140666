@import "constants/index.js";

.sampleExportModal {
  .dateRangeSelectorContainer {
    border: 1px solid #ddd;
    border-radius: 3px;
    color: $textColour;
    height: 30px;
    padding: 3px 10px;

    button {
      border: none !important;
    }
  }
}

@import "constants/index.js";

.recordOfQualityModal {
  display: flex;
  flex-direction: column;
  margin: 15px auto;
  width: 85%;
  min-width: 1250px;
  min-height: 800px;

  .modal-body {
    padding: 0px 15px;
  }

  .loadedContent {
    height: 100%;
  }

  &__content {
    height: 100%;
    padding: 0 32px;
  }

  &__tabItem {
    padding-top: 11px;
  }

  &__tabs {
    height: 100%;
    // allows for "flex 1 1"
    display: flex;

    .tab-content {
      // fills up rest of space (other than nav-tabs)
      flex: 1 1;
      width: 100%;

      // all tab content panes should be 100% of remaining space
      > * {
        height: 100%;
      }

      // if active, let some items be able to take up rest of space
      > .active {
        display: flex;
        flex-direction: column;
      }
    }
    .nav-tabs {
      border-bottom: 1px solid #ddd;
      margin-bottom: 10px;

      li {
        flex: 1 1 auto;
        text-align: center;

        span {
          text-transform: capitalize;
          vertical-align: middle;
        }
      }
    }
  }

  &__title {
    display: flex;
    align-items: center;

    > * + * {
      margin-left: 10px;
    }

    & .recordOfQuality__status {
      font-size: 14px;
    }
  }

  &__overview {
    display: flex;
    height: calc(100% - 20px);
    margin: 10px 0px;

    > * {
      width: 50%;
    }

    > * + * {
      margin-left: 15px;
    }
  }

  & &__saveStatus {
    display: flex;
    align-items: center;
    border: 1px solid $borderColour;
    border-radius: 3px;
    padding: 0 5px;
    font-size: 14px;

    .icon {
      color: $linkColour;
      font-size: 10px;
    }
  }

  &__error {
    margin: auto;
  }

  .componentLoader {
    margin-top: 0;
  }
}

// modifies bootstrap to allow scrolling if below min-width
.modal-open .modal {
  overflow-x: auto;
}

.toolboxContainer {
  display: flex;
  flex-direction: column;
  margin-top: 8px;
}

.toolboxActions {
  display: flex;
  gap: 16px;
}

.toolboxAction {
  flex-grow: 1;
}

.dailyVolumeModal {
  width: 85%;
  min-width: 1250px;

  &__body {
    height: 970px;
    padding-top: 0px;
    position: relative;
  }

  &__content {
    > * {
      margin-top: 10px;
    }
  }

  .componentLoader {
    margin-top: 0%;
  }
}

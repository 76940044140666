.confirmationModal {
  min-height: 150px;
}

.confirmationModal__footer {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
}

.confirmationModal .confirmButton {
  box-shadow: none;
  max-width: 350px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

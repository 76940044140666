// colours

.darkBlue {
  background-color: var(--colour-text-800);
  color: #ffffff;
}

.red {
  background-color: var(--colour-error-light);
  color: var(--colour-error);
}

// marker point

.marker {
  border-color: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-radius: 50%;

  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

.icon {
  padding: 5px 5px 0px 5px;
}

// for no icon ONLY
.small {
  padding: 4px;
}

// for no icon ONLY
.large {
  padding: 10px;
}

// label

.label {
  background-color: rgba(white, 0.75);
  color: var(--colour-text-800);

  border-color: #ffffff;
  border-width: 2px;
  border-style: solid;
  border-radius: 5px;

  padding: 2px 4px;

  font-weight: 600;
  box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.25);
}

// half the height of marker + 5
.labelForLargeIcon {
  // 44/2 + 5 = 27
  margin-top: 27px;
}
.labelForSmallIcon {
  // 29/2 + 5 = 19.5
  margin-top: 19.5px;
}
.labelForLargeNoIcon {
  // 24/2 + 5 = 17
  margin-top: 17px;
}
.labelForSmallNoIcon {
  // 12/2 + 5 = 11
  margin-top: 11px;
}

@import "constants/mixins";

.section {
  margin-top: 20px;
}

.dropdownContainer {
  > * + * {
    margin-top: 6px;
  }

  :global(.dropdown button) {
    padding: 5px 15px;
    border: 1px solid #ddd;
    border-radius: 3px;
    text-transform: capitalize;
    min-width: 125px;
    max-width: 100%;
    // below needed to set a max width and still have caret visible
    text-align: left;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;

    & > span {
      position: absolute;
      right: 5px;
      top: 50%;
      transform: translate(0, -50%);
    }
  }
}

.dropdownTitle {
  @include fontStyles(body, 14, true);
  display: flex;

  &__icon {
    display: inline-block;
    margin-left: 3px;
  }
}

.streamDropdown {
  width: 100%;
}

.selectionDropdownSection {
  display: flex;

  > * {
    flex: 1;

    + * {
      margin-left: 10px;
    }
  }

  :global(.dateRangeSelectorContainer),
  :global(.dropdownInputWithSearch button) {
    width: 100%;
  }
}

.chartDropdownSection {
  margin-top: 20px;
  display: flex;
  justify-content: flex-end;

  > * + * {
    margin-left: 12px;
  }
}

.monthlyView .DayPicker {
  width: 100%;
}

.monthlyView .DayPicker-Month {
  width: 100%;
}

.monthlyView .DayPicker-wrapper {
  outline: 0;
}

.monthlyView .DayPicker-Caption {
  display: none;
}

.monthlyView .DayPicker-Day {
  border: 1px solid #ddd;
  vertical-align: top;
}

.monthlyView .DayPicker-Day:focus {
  outline: 0;
}

.monthlyView .DayPicker-Day .dayCell__events {
  text-align: left;
  margin-left: 5px;
  font-size: 11px;
}

.monthlyView__dayCellEvent {
  white-space: nowrap;
  overflow: hidden;
  padding: 0 5px 0 5px;
  text-overflow: ellipsis;
}

.monthlyView__dayCellEvent:hover {
  background-color: #eeeeee;
  border-radius: 5px;
  padding: 0 5px 0 5px;
  cursor: pointer;
}

.monthlyView .DayPicker-Day--today {
  font-weight: normal;
  color: #555;
}

.monthlyView .DayPicker-Day--today .dayCell .dayCell__date {
  background: #4dc7c8;
  width: 29px;
  height: 23px;
  text-align: center;
  display: inline-block;
  border-radius: 30px;
  color: #fff;
}

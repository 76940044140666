.userActivity__dateRangeContainer {
  height: 35px;
}

.userActivity__dateRange {
  color: var(--link-colour);
  display: inline-block;
  font-size: 12px;
}

.userActivity__dateRangeContainer .dateRangeInput input {
  border: none;
  width: 75px;
}

.recordOfQualityTable {
  background: #ffffff;
  margin-top: 15px;
  min-width: 700px;

  & .buttonLink {
    font-size: 12px;
    background: none;
  }

  & .buttonLink:hover {
    font-size: 12px;
    background: none;
  }

  & &__warningIcon {
    color: #ff9800;
  }

  & &__streamCell {
    text-transform: capitalize;
    white-space: normal;
  }

  & &__volumeCell {
    text-align: right;

    .icon {
      margin-left: 5px;
    }
  }

  &__methodCell {
    white-space: normal;
  }

  .openingInventory__cell {
    height: 55px;
  }
}

.recordOfQualityTable__tooltip {
  & &__header {
    font-size: 14px;
    text-align: left;
    font-weight: bold;
  }
}

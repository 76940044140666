@import "constants/index.js";

.recordOfQualityResultTable {
  flex-grow: 1;
  padding-left: 20px;

  &__title {
    margin-bottom: -10px;
  }

  & &__row {
    text-align: right;
    &--bold {
      font-weight: bold;
    }
    &--empty {
      text-align: center;
    }
  }

  .ReactVirtualized__Table__headerColumn {
    text-transform: none;
    text-align: center;
  }

  .ReactVirtualized__Table__row:last-of-type {
    border-bottom: none;
  }
}

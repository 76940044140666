.container {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.detailsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.entityTableContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.flexedContainer {
  flex: 1;
}

.statusContainer {
  width: 312px;
}

.createApiTokenModal {
  &__apiTokens {
    margin-top: 15px;

    .showSecretButton {
      margin-right: 5px;
    }
  }

  table {
    td {
      vertical-align: top;
      padding-left: 10px;
      width: 150px;
    }
  }

  &__footer {
    display: flex;
    justify-content: space-between;
  }
}

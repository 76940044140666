// ideally overviewAndMapSection will be replaced once we redo layouts
// originally Grid was attempted to replace but default margin-bottom on panels
// create weird spacing issues

.overviewAndMapSection {
  display: flex;
  gap: 10px;

  & > * {
    flex: 1;
  }
}

.overviewPanel {
  margin-bottom: 0px;
}

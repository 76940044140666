.resultsBalanceTable {
  height: 360px;

  &__name {
    white-space: normal;
    line-height: 1.25;
  }

  &__measurement {
    display: flex;
    flex-wrap: nowrap;

    &__value {
      width: 95%;
      text-align: right;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      flex-grow: 1;
    }

    &__unit {
      margin-left: 2px;
      text-align: right;
    }
  }
}

@import "constants/mixins";

.section-container {
  section {
    border-bottom: 1px solid var(--colour-surface-200);
    margin-bottom: 16px;
    .title {
      @include fontStyles(body, 16, true, 600);
      margin-bottom: 16px;
      display: inline-block;
    }
    .readonly-container {
      display: flex;
      flex-direction: column;
      gap: 8px;
      margin-bottom: 16px;
    }
    .readonly-wrapper {
      display: flex;
      flex-direction: row;
      gap: 4px;
      align-items: center;
    }
    .banner-container {
      margin: -8px 0 8px;
      display: flex;
      gap: 8px;

      .icon {
        color: var(--colour-warning, #ff8f27);
      }
      .description {
        color: var(--colour-warning-dark, #e37006);
      }
    }
  }

  section:last-child {
    border-bottom: none;
  }
}

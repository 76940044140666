.dailyVolumeTable {
  background-color: #ffffff;
  border-radius: 3px;

  &__entryRequired {
    background-color: #ffe0b34f;
  }

  &__wrapTextCell {
    white-space: normal;
    height: 60px;
  }
}

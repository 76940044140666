.rolePermission__title {
  text-align: right;
  width: 100%;
}

.rolePermissionListContainer {
  margin-left: -10px;
}

.permissionListRow {
  height: 40px;
}

.permissionListRow__title {
  display: inline-block;
  text-transform: capitalize;
}

.permissionListRow__value {
  border: 1px solid #ddd;
  text-align: center;
  text-transform: capitalize;
  padding: 5px 20px;
  width: 160px;
}

.rolePermissionList {
  border: 1px solid #ddd;
  height: 200px;
  padding: 10px;
  overflow-y: scroll;
}

.measurementControlPanel__tableHeaders th,
.measurementControlPanel__measureButtons {
  text-align: center;
}

.measurementControlPanel__measureButtons > * {
  margin: 0 1em;
}

.measurementControlPanel__measurementValue {
  text-align: center;
}

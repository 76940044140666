.measurementStatus {
  display: flex;
  flex-direction: column;
  font-size: 12px;
  margin-top: -5px;
  width: 85%;
}

.measurementStatus__textRow {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.measurementStatus__value {
  display: flex;
  flex-direction: row;
}

.measurementStatus__status {
  display: flex;
  flex-direction: row;
  text-transform: capitalize;
  max-width: 70%;
  overflow: hidden;
  white-space: nowrap;
}

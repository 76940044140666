.emptyState {
  width: 100%;
  text-align: center;
  margin-top: 300px;

  &.panel {
    margin: 180px 0;
  }

  & > p {
    margin: 0;
  }

  & > .error {
    color: #0c2836;
    font-weight: bold;
  }

  & > .helper {
    color: #a0acb2;
    font-style: italic;
    margin-bottom: 16px;
  }
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.loadingIndicatorContainer {
  height: 450px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingIndicator {
  animation-name: spin;
  animation-duration: 500ms;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}

.actions {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin: 0 0 16px 0;
}

.systemBalanceSummary {
  margin-bottom: 15px;

  .systemBalanceProperty {
    display: flex;
    flex-direction: row;
    margin-bottom: 5px;

    &__value {
      text-align: right;
      flex-grow: 2;
    }
  }
}

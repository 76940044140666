.recordOfQualityNotes {
  height: 100%;
  display: flex;
  flex-direction: column;

  &__listContainer {
    flex: 1 1 400px;
  }

  &__input {
    margin: 25px 0 20px;
  }
}

.sampleAnalysis {
  min-height: 900px;

  & &__overview {
    display: flex;
    flex-direction: row;
    padding-top: 5px;

    .summaryInformation {
      margin-right: 20px;
    }
  }

  &__summary {
    padding: 15px 0;
  }

  &__addTest {
    display: inline-block;
  }

  &__downloadableReports {
    margin-bottom: 15px;
  }

  &__analysis {
    height: 665px;
  }

  &__footer {
    height: 40px;
  }

  .sampleComparisonTable {
    height: 650px;

    &__measurementCell {
      width: 125px;
      overflow-x: auto;

      .linkableText {
        margin-right: 8px;
      }
    }
  }

  &__applicablePeriod {
    line-height: 1.2;
  }
}

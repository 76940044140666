.contentContainer {
  display: grid;
  grid-template-columns: auto;
  gap: 16px;
}

.title {
  color: var(--colour-surface-600, #1b4254);
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1px;
  margin-bottom: 0;
}

.keyValueListContainer {
  border-radius: 6px;
  padding: 0px 12px;
  background-color: var(--colour-surface-75);
  border-color: #e6eaec;
}

.buttonContainer {
  display: flex;
  justify-content: flex-end;
  gap: 8px;
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.commentsButton {
  width: fit-content;

  &--active {
    width: fit-content;
    border: 1px solid var(--colour-surface-200);
    background-color: var(--colour-surface-75);
  }
}

.qualitySummary {
  > * + * {
    margin-top: 20px;
  }

  &__summary {
    max-height: 80px;
    display: flex;
    justify-content: space-around;

    > :first-child {
      max-width: 33%;
    }
  }

  &__divider {
    border-left: 1px solid #ddd;
    padding: 0px 10px;
  }

  &__table {
    height: 450px;
    width: 100%;

    .datatable__headerGrid {
      background-color: #f6f6f6;
      border-radius: 3px;
    }
  }
}

.profile .profileContainer {
  max-width: 800px;
  margin-bottom: 15px;
}

.profile__panel {
  margin-top: 15px;
  height: 100%;
}

.profile__userName {
  font-size: 15px;
  font-weight: bold;
  margin-bottom: -4px;
  text-transform: capitalize;
}

.profile__detailList {
  margin: 20px 0 0 8px;
}

.profile__detailList .icon {
  color: var(--light-text-colour);
}

.profile__detailRow {
  margin-top: 5px;
}

.profile__button {
  display: block;
  border: 1px solid #ddd;
  box-shadow: none;
  margin: 15px 0 10px 0;
}

.activityCell__description {
  display: inline-block;
  vertical-align: text-bottom;
}

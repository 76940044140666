@import "constants/index.js";

.adjustmentCell {
  &__tooltip {
    width: 325px;
    ul {
      padding-left: 0px;
    }

    li {
      text-align: left;
    }

    &.tooltip .tooltip-arrow {
      border-bottom-color: $tooltipColour;
    }

    .tooltip-inner {
      background-color: $tooltipColour;
    }
  }
}

.detailsStepContainer {
  max-width: 244px;
}

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.selectedAssetsPanelContainer {
  width: 400px;
}

.connectionsPanelsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;

  & :first-child {
    flex: 1;
  }
}

.container {
  display: flex;
  flex-direction: row;
}

.estimatedValueContainer {
  .test {
    font-weight: normal;
  }
}

.netFacilityChartContainer {
  flex-grow: 1;
}

.optionsContainer {
  display: flex;
  flex-direction: row;
  justify-content: right;
}

.container {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  gap: 32px;
}

.contentContainer {
  flex: 1;
}

.alertDigestEmailSettings__weekdayPicker {
  box-shadow: none;
  display: inline-block;
  border: 1px solid #183e56;
  font-size: 13px;
  font-weight: bold;
  border-radius: 3px;
  margin: 0px 0px 0px 10px;
  padding: 0px 0px 0px 8px;
  height: 26px;
  width: 95px;
  box-sizing: border-box;
  position: relative;
}

.alertDigestEmailSettings__weekdayPicker.alertDigestEmailSettings__disabledControl {
  color: rgb(204, 204, 204);
}

.alertDigestEmailSettings__timePicker {
  background: #eeeeee;
  border: 1px solid #183e56;
  border-radius: 3px;
  margin: 0px 10px;
  padding: 0px 8px;
  width: 95px;
  display: inline-block;
  position: relative;
}

.alertDigestEmailSettings__disabledControl,
.alertDigestEmailSettings__timePicker .rc-time-picker-input[disabled] {
  background: #eeeeee;
}

.alertDigestEmailSettings__timePicker .rc-time-picker-input,
.alertDigestEmailSettings__timePicker .rc-time-picker-input:focus {
  background: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: bold;
  height: 24px;
  width: 65px;
}

.alertDigestEmailSettings__checkbox {
  display: inline-block;
}

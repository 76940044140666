.summaryContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.summaryContainerItem {
  width: 250px;
}

.downloadableReportContainer {
  height: 200px;
  flex-wrap: wrap;
  gap: 8px;
}

.existingSampleForm {
  .row {
    margin: 0;
  }

  & &__table {
    height: 500px;
  }
}

.existingSampleModal {
  width: 900px;
}

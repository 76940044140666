.annotationStage__flexContainer {
  display: flex;
  align-items: center;
  font-size: 2em;
}

.annotationStage__flexContainer .label-default {
  background: none;
  color: inherit;
}

.annotationStage__approximateMethodDisplay {
  font-size: 0.5em;
}

.analyze .col-xs-12 {
  padding: 0;
}

.analyze__filters {
  margin-bottom: 16px;
}

.analyzeAddPanel .addPanel {
  border: 2px dashed var(--validere-colour);
  height: 100px;
  padding-top: 27px;
  text-align: center;
  cursor: pointer;
}

.analyzeAddPanel .addPanel .addPanel__text {
  color: #888;
  font-size: 15px;
}

.analyzeAddPanel .addPanel .addPanel__text:hover {
  text-decoration: underline;
}

.analyzeAddPanel .addPanel .addPanel__text .addPanel__icon {
  font-size: 20px !important;
}

.measurementControlPanel__submitPanel {
  text-align: center;
}

.measurementControlPanel__submitButton[disabled] {
  pointer-events: none;
}

.measurementControlPanel__submitButton__overlayFix {
  /*
  `react-bootstrap` will not trigger an `OverlayTrigger` on a disabled Button for 'reasons'.
  To get around this, we have to wrap the disabled Button in an element that will trigger the overlay
  instead.
  */
  display: inline-block;
  cursor: not-allowed;
  vertical-align: top;
}

.container {
  display: grid;
  place-content: center;
  place-items: center;
  width: 100%;
  min-height: 100vh;
  text-align: center;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: var(--zindex-loader);
  background: rgba(255, 255, 255, 0.95);
}

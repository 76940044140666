@import "constants/mixins";

.balancesTable {
  &__tooltip {
    .tooltip-inner {
      width: 275px;
      max-width: 275px;
    }
  }

  &__tooltipBase {
    text-decoration: underline;
  }

  &__tooltipContent {
    overflow: auto;
    max-height: 250px;
  }

  &__tooltipTitle {
    text-align: left;
    @include fontStyles(body, 16);
    color: var(--colour-white);
  }
}

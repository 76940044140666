.calibrationRow.calibrationRow > td {
  border: 0;
}

.calibrationRow.calibrationRow > th {
  border: 0;
}

.instrumentCalibration__errorMessage {
  background: #fbfbfc;
  text-align: center;
}

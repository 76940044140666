.systemBalanceChartContainer {
  background: #ffffff;
  height: 250px;

  .systemBalanceChart__selectionRow {
    margin-bottom: 5px;
    height: 25px;

    .dropdown button {
      font-size: 12px;
      border: 1px solid #ddd;
      padding: 0 10px;
      box-shadow: none;
    }
  }
}

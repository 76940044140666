.container {
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > div:first-child {
    flex: 1;
  }

  & > div:last-child {
    width: 420px;
  }
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

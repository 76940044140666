.volumeNotes {
  display: flex;
  flex-direction: column;
  padding: 0;

  > * {
    margin-top: 10px;
  }

  &__filterRow {
    > * + * {
      margin-left: 10px;
    }
  }

  &__notesContainer {
    position: relative;
    flex: 1 1 400px;

    .componentLoader {
      top: 50%;
      margin-top: 0;
    }
  }

  &__addNotesToInput {
    margin-top: 25px;
    margin-bottom: 0px;
  }
}

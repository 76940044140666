.inventoryTable {
  &__wrapTextCell {
    white-space: normal;
    height: 60px;
  }

  &__volumeCell {
    &__volumeTitle {
      display: flex;
      flex-direction: row;
      margin-bottom: -10px;

      .volume {
        flex-grow: 1;
      }

      .percentage {
        font-weight: bold;
      }
    }
  }
}

.summaryInformation {
  &__title {
    font-weight: bold;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  &__value {
    margin-left: 5px;
    display: flex;
    flex-wrap: nowrap;
    white-space: nowrap;

    .value,
    .estimatedValue {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    .gevTooltip {
      display: flex;
      width: 100%;
    }

    .unit {
      margin-left: 2px;
    }

    .icon {
      font-size: 12px;
      margin-left: 5px;
    }
  }
}

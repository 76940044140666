.mapContainer {
  background-color: #fff;
  border: 1px solid #e6eaec;
  border-radius: 6px;
  box-shadow: 0px 0px 20px rgba(16, 55, 75, 0.0509803922);
  margin-bottom: 16px;
  height: -moz-max-content;
  height: max-content;
  padding: 0px;
  margin: 0px;
  position: relative;
  overflow: hidden;
  width: 100%;
  height: 100%;
}

.introContainer {
  display: flex;
  flex-direction: row;
  margin-top: 16px;
}

.instructionsContainer {
  display: flex;
  flex-direction: column;
  max-width: 600px;
}

.detailsContainer {
  max-width: 244px;
  margin-top: 16px;
}

.container {
  display: grid;
  gap: 16px;
  grid-template-columns: 60fr 40fr;
}

.tableContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.selectContainer {
  display: flex;
  flex-direction: column;
}

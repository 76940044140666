.statisticPanel {
  background: #ffffffbf;
  border: 1px solid #ddd;
  border-radius: 3px;
  color: #666;
  cursor: pointer;
  display: inline-block;
  font-size: 11px;
  margin: 10px 10px 0 0px;
  max-width: 175px;
  padding: 5px 10px;
}

.statisticPanel .panel-body {
  padding: 5px;
}

.statisticPanel__header {
  height: 19px;
  min-width: 65px;
}

.statisticPanel__headerName {
  display: inline-block;
  font-weight: bold;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.statisticPanel__closeButton {
  color: #9e9e9e;
  font-size: 13px;
  font-weight: normal;
  margin-top: 2px;
}

.statisticPanel__table {
  width: 100%;
}

.statisticPanel__tableValue {
  text-align: right;
}

.footer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.associatedPermissionsBanner {
  margin-top: 16px;
}

.emissionChart {
  height: 300px;
  width: 100%;
}

.emissionTable {
  height: 300px;
  width: 100%;
}

.emissionDropdownContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
}

.emissionDropdown {
  margin-right: 8px;
}

.facilityTable {
  height: 300px;
  width: 100%;
}

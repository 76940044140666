.formTitle {
  margin-bottom: 15px;
}

.button {
  margin-bottom: 15px;
}

.priceInput {
  display: flex;
  align-items: baseline;

  > * {
    margin-right: 10px;
  }
}

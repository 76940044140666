@import "constants/index.js";

.adjustmentFactors {
  &__compoundTooltip {
    &.tooltip .tooltip-arrow {
      border-bottom-color: $tooltipColour;
    }

    .tooltip-inner {
      background-color: $tooltipColour;
      max-width: 300px;
    }
  }

  &__actionRow {
    .Button-module__button--primary {
      margin-left: 10px;
    }
  }
}

@import "constants/index.js";

.manualEntry {
  max-width: 1440px;

  &__title {
    margin: 15px;
  }

  &__panelContainer {
    display: flex;
    flex-wrap: wrap;
  }

  &__typePanel {
    height: 200px;
    width: 275px;
    margin-right: 25px;

    &__icon {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      margin-top: 60px;
      color: $linkColour;
      font-size: 20px;
    }

    .title {
      margin-top: 30px;
      text-align: center;
    }
  }
}

@import "constants/index.js";

.workflowTaskNotes {
  margin-bottom: 10px;
  padding: 0;

  > * + * {
    margin-top: 20px;
  }

  &__listContainer {
    height: 410px;
    position: relative;
    margin-top: 10px;
  }

  &__buttonRow {
    > * + * {
      margin-left: 10px;
    }
  }

  .importWithTextChatBox__buttonContainer {
    margin-top: 20px;
  }
}

@import "constants/mixins";

.dropdownContainer {
  > * + * {
    margin-top: 6px;
  }
}

.dropdownTitle {
  @include fontStyles(body, 14, true);
}

.dropdown {
  width: 300px;
}

.streamDropdown {
  width: 100%;
}

.selectionDropdownSection {
  display: flex;
  > * {
    flex: 1;
  }
}

@import "constants/mixins";

.tooltipContent {
  overflow: auto;
  max-height: 250px;
}

.tooltipBase {
  text-decoration: underline;
  cursor: pointer;
}

.tooltipTitle {
  text-align: left;
  @include fontStyles(body, 16);
  color: var(--colour-white);
}

.latestSamplesPanel__noSampleMessage {
  text-align: center;
  margin-top: 50px;
}

.latestSamplesPanel__content {
  overflow-y: auto;
  height: 300px;
  box-shadow: none;
  -webkit-box-shadow: none;
}

.latestSamplesPanel .list-group-item {
  padding-top: 5px;
  padding-bottom: 0;
  border-radius: 0;
  margin-bottom: 5px;
  background: #fbfbfb;
  border: 0;
}

.latestSamplesListItem__title {
  font-size: 13px;
}

.latestSamplesListItem__icon {
  margin-top: 15px;
  font-size: 17px;
}

.latestSamplesListItem__time {
  margin-right: 10px;
}

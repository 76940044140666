@import "constants/mixins";

.title {
  @include fontStyles(headline, lg, false);
  font-weight: 400;
  color: var(--colour-text-800);
  display: inline-flex;
  align-items: center;
  gap: 4px;
  margin-top: 4px;
}

.sectionHeading {
  color: var(--colour-text-800);

  &.h2 {
    @include fontStyles(headline, md, true, 600);
  }

  &.h3 {
    @include fontStyles(headline, sm, false);
    color: var(--colour-text-600);
  }

  &.h4 {
    @include fontStyles(body, 16, false);
    color: var(--colour-text-400);
  }

  &.closeToHeadingBelow {
    margin-bottom: 4px;
  }

  &.closeToHeadingAbove {
    margin-top: 4px;
  }
}

.section {
  .ctxBanner {
    margin-bottom: 4px;
  }
}

.sectionSubHeading {
  @include fontStyles(headline, sm, false);
  color: var(--colour-text-400);
  margin-top: 0;
}

.sampleValidationModal {
  min-height: 800px;

  &__content {
    padding: 16px 32px 0 32px;
  }

  .modal-lg {
    min-width: 1080px;
  }

  .nav-tabs {
    border-bottom: 1px solid #ddd;
    margin-bottom: 15px;
  }
}

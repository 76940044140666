.monthlyView__popupWorkflowDetailContainer {
  height: 100%;
  overflow-y: auto;
}

.monthlyView__popupTitle {
  margin-top: -5px;
}

.monthlyView__popupDate {
  margin-bottom: 5px;
}

.monthlyView__popupRowContainer {
  margin-bottom: 10px;
}

.monthlyView__popupRowIcon {
  color: #3cb8b9;
  font-size: 18px;
  margin-right: 18px;
  vertical-align: top;
  display: inline-block;
}

.monthlyView__popupRow {
  display: inline-block;
  margin-top: 4px;
  width: 85%;
}

.monthlyView__taskListContainer {
  display: inline-block;
  height: 180px;
  margin-top: 8px;
  width: 310px;
  overflow-x: auto;
}

.monthlyView__taskListContainer .taskRowRenderer {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.monthlyView__taskListContainer .taskRowRenderer .icon {
  font-size: 14px;
}

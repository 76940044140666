.fieldManualEntryConfirmationModal {
  &__testDetail {
    margin-bottom: 20px;

    .title {
      margin-bottom: 10px;
    }

    .summaryInformation {
      padding-top: 0px;
    }
  }

  &__sampleDetail {
    .fieldManualEntry__selectedSample {
      margin-top: 10px;
    }
  }

  &__actionRow {
    > * + * {
      margin-left: 10px;
    }
  }
}

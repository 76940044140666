@import "constants/index.js";

.filledVolume {
  padding-right: 20px;
  border-right: 1px solid $borderColour;
  height: 100%;

  &__subtitle {
    margin-bottom: 20px;
  }

  &__percent {
    font-size: 20px;
    font-weight: bold;
  }

  &__time {
    margin-bottom: -10px;
  }

  &__divider {
    border-bottom: 1px solid $borderColour;
    margin-top: 10px;
  }

  &Row {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;

    &__title,
    &__value {
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }

    &__value {
      flex: 0 0 auto;
      margin-left: 20px;
    }
  }
}

.submitModal .modal-container {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}

.submitModal .modal-container .modal,
.submitModal .modal-container .modal-backdrop {
  position: absolute;
}

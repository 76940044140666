@import "constants/mixins";

.tooltipContent {
  overflow: auto;
  max-height: 250px;
  list-style-type: none;
  padding: 0;
}

.tooltipBase {
  text-decoration: underline;
  cursor: pointer;
}

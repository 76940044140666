@import "constants/index.js";

.previewModal {
  text-align: center;
}

.imageContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 750px;
}

.hidden {
  display: none;
}

.show {
  display: block;
}

.footer {
  width: 100%;

  .stepActionContainer {
    float: right;
    display: flex;
    gap: 8px;
  }
}

.dataMappingDetailContainer dt {
  margin-bottom: 16px;
  text-transform: capitalize;
}

.monthlyView__popupEventsContainer {
  width: 100%;
}

.monthlyView__popupDay {
  font-size: 22px;
  text-align: center;
}

.monthlyView__popupEvent {
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

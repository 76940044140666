.recordOfQualitySampleChart {
  height: 250px;
  position: relative;
  margin-top: 20px;
  margin-bottom: 37px;

  &__container {
    height: 250px;
  }

  & &__horizontalGridLine {
    .rv-xy-plot__grid-lines__line {
      stroke: #ddd;
      fill: #ddd;
      &:nth-of-type(5n + 1) {
        stroke: #999;
        fill: #999;
        stroke-width: 2;
      }
      &:last-of-type {
        stroke: #ddd;
        fill: #ddd;
      }
    }
  }

  & &__verticalGridLine {
    .rv-xy-plot__grid-lines__line {
      stroke: #999;
      fill: #999;
    }
  }

  &__labelTitle {
    text {
      font-size: 13px;
    }
  }

  &__clickable {
    cursor: pointer;
  }

  &__noData {
    text-align: center;
    padding-top: 150px;
  }

  .rv-hint {
    &__content {
      background-color: rgba(0, 0, 0, 0.8);
      position: relative;
      z-index: var(--zindex-tooltip);
      width: 235px;
      .rv-hint__sectionTitle {
        color: #fff;
        margin: 0;
        margin-bottom: 7px;
        font-weight: bold;
      }
    }

    &__details {
      margin-bottom: 7px;
    }

    &__row {
      line-height: 1.4;
      padding: 0px 3px;
    }

    &__value {
      font-weight: bold;
      float: right;
    }

    &__alarms {
      margin-top: 14px;
      ul {
        margin: 0;
        margin-bottom: 7px;
        padding-left: 18px; // bullet point needs 15px to center
        text-align: left;
        white-space: normal;
        word-break: break-word;
        line-height: 1.4;
        list-style: none;

        // creating a white bullet
        li::before {
          content: "\2022"; // bullet unicode
          color: #fff;
          font-size: 20px;
          vertical-align: middle;
          font-weight: bold;
          display: inline-block;
          width: 12px; // indentation of first line
          margin-left: -15px; // indentation of other lines
          line-height: 0;
        }
      }
    }
  }
}

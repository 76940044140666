@import "constants/mixins";

.drawerTitle {
  margin: 0;
  font-size: 20px;
  font-weight: 400;
  color: var(--colour-text-800);
}

.titleContainer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 16px;
}

.buttonsContainer {
  display: flex;
  gap: 4px;
  align-items: center;
}

.lastUpdatedPanel {
  margin-top: 16px;

  .panelTitleContainer {
    margin-bottom: 8px;
  }
}

.sectionTitle {
  @include fontStyles(body, 14, true, 400);
  margin: 8px 0;
}

.drawerActionRow {
  display: flex;
  gap: 8px;
  justify-content: flex-end;
}

.lockedBanner {
  margin-bottom: 16px;
}

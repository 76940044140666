/* Instrument Samples: Sample Export */
.sampleSettings__exportButton {
  background-color: var(--validere-colour);
  color: #fff;
}

.instrumentPanels__header {
  color: #527986;
}

.instrumentPanels {
  margin-top: 35px;
  box-shadow: none;
  background: #f0f0f0;
}

.instrumentPanels .panel-heading {
  background-color: #ffffff;
  color: #527986;
  font-size: 18px;
  margin-bottom: -15px;
}

.instrumentPanels .actionRow {
  height: 35px;
}

.account-menu-companies-accordion-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
}

.account-menu-companies-accordion {
  width: 100%;
  padding: 0 16px 0 16px;
}

.account-menu-companies-accordion-panel {
  &[data-state="open"] [role="region"] {
    margin-top: 0;
    margin-bottom: 0;
  }
}

.account-menu-companies-accordion-title-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 0;
}

.account-menu-companies-accordion-title {
  margin-left: 8px;
  margin-bottom: 0;
  color: var(--colour-text-600);
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.14px;
}

.account-menu-companies-accordion-panel-content-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.account-menu-companies-search-input {
  margin-top: 8px;
  width: 100%;
}

.account-menu-company-items-container {
  max-height: 258px;
  width: 100%;
  overflow-y: scroll;
}

.account-menu-company-item-container {
  border-bottom: 1px solid var(--colour-surface-200);
}

.no-border-bottom {
  border-bottom: none;
}

.account-menu-company-item {
  display: flex;
  gap: 12px;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  border-radius: 6px;
  cursor: pointer;

  &.account-menu-current-company {
    border-radius: 6px;
  }

  &:hover {
    background-color: var(--colour-surface-75);
  }
}

.account-menu-company-item-icon-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1px;
  min-width: 24px;
  height: 24px;
  border-radius: 6px;
  background-color: #7268b4;
}

.account-menu-company-item-text-container {
  display: flex;
  flex-direction: column;
  font-size: 14px;
  line-height: 18.2px;
}

.account-menu-company-item-name-text {
  color: var(--colour-text-800);
  font-weight: 600;
  margin-bottom: 0;
}

.account-menu-current-company {
  border: 1px solid #447c9c;
}

.account-menu-no-accounts-found-text {
  color: var(--colour-text-400);
  font-weight: 400;
  font-style: italic;
  margin-bottom: 0;
}

.accessDefinitionsContainer {
  margin-top: 15px;
}

.accessDefinitions {
  margin-top: 15px;
}

.accessDefinitions__title {
  font-weight: bold;
  text-decoration: underline;
  text-transform: capitalize;
}

.accessDefinitions__definitions {
  margin-bottom: 0;
}

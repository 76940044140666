@import "constants/index.js";

.labResultsTest {
  display: flex;
  flex-direction: row;
  padding: 0 10px;
  height: 23px;
  overflow: hidden;
  justify-content: flex-end;

  & &__value {
    margin-left: 5px;

    .buttonLink {
      background-color: transparent;
    }

    .alert {
      color: $statusErrorColour;
    }
  }

  & &__label {
    width: 100%;
    text-transform: capitalize;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: default;

    span:first-child {
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1 1;
    }
  }

  &__tooltip {
    text-transform: capitalize;
  }

  .icon {
    margin-left: 5px;
  }
}

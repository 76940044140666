.container {
  width: 100%;
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.button {
  flex: 1;
}

@import "constants/mixins";

.container {
  min-width: 900px;
}

.controls-container {
  margin: 0 0 20px 0;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.actions-container {
  display: flex;
  gap: 10px;
}

.buttonRow {
  height: 35px;
  display: inline-block;

  > * + * {
    margin-left: 10px;
  }
}

.secondaryButton {
  background-color: var(--colour-white);
}

.buttonsRow {
  display: flex;
  justify-content: space-between;
  button {
    &:nth-child(2) {
      margin-left: auto;
      margin-right: 0;
    }
    &:nth-child(3) {
      margin-left: 10px;
    }
  }
}

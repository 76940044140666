.basicInfoContainer {
  max-width: 400px;
}

.userSelectionContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.userTableContainer {
  flex: 1;
}

.connectionsPanelsContainer {
  display: flex;
  flex-direction: row;

  @media (max-width: 1280px) {
    flex-direction: column-reverse;
  }

  gap: 16px;

  & :first-child {
    flex: 1;
  }
}

.selectedAssetsPanelContainer {
  max-width: 800px;
  @media (max-width: 1280px) {
    max-width: 100%;
  }
  flex: 1 1 400px;
}

.layoutContainer {
  display: flex;
  gap: 16px;
  flex-direction: column;

  @media (min-width: 1280px) {
    flex-direction: row;
  }
}

.configurationContainer {
  flex-basis: 25%;

  @media (min-width: 1280px) {
    align-self: flex-start;
    position: sticky;
    top: 10px;
  }
}

.dataContainer {
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;

  @media (min-width: 1280px) {
    max-width: 75%;
  }
}

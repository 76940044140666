.inlineManualEntry {
  &__page {
    max-width: 900px;
  }

  &__measurementSection {
    margin-bottom: 25px;

    .removeMeasurementsButton {
      margin-bottom: 25px;
    }
  }

  &__divider {
    border-top: 1px solid #ddd;
    margin-bottom: 25px;
  }

  &__actions {
    width: 33%;
    min-width: min-content;
    margin: 0 auto;
  }

  & &__addButtonRow {
    margin-bottom: 25px;
    display: flex;
    justify-content: center;
  }
}

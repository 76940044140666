@import "constants/index.js";

.balanceSummaryChart {
  height: 300px;
}

.balanceSummaryChartHeader {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
  justify-content: space-between;
  &__title {
    font-size: 15px;
    font-weight: bold;

    .icon {
      margin-left: 5px;
    }
  }

  &__toggle .btn-group button {
    box-shadow: none;
    border: 1px solid #ddd;
    outline: none;
    font-size: 10px;
    padding: 5px 10px;
  }
}

@import "constants/index.js";

.fieldManualEntry {
  .addNewButton {
    margin-right: 10px;
  }

  &__selectedSampleSummaryContainer {
    margin-top: 10px;
  }

  &__selectedSample {
    border: 1px solid $borderColour;
    padding: 5px 10px;

    &__body {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__selectedSample.disabled {
    background-color: var(--colour-surface-75);
    color: var(--colour-text-400);
    border: 1px solid $borderColour;
    padding: 5px 10px;

    &__body {
      display: flex;
      flex-wrap: wrap;
    }
  }

  &__removeSelectedSample {
    display: inline-block;
    margin-top: 10px;
  }

  &__measurement {
    display: flex;
    flex-direction: row;
    align-items: baseline;
    flex-wrap: wrap;

    > div {
      flex: 1;
    }

    > * + * {
      margin-left: 5px;
    }
  }

  &__divider {
    border: 1px solid $borderColour;
    margin-bottom: 25px;
  }

  &__actions {
    width: 33%;
    min-width: min-content;
    margin: 0 auto;
    display: flex;
    justify-content: space-between;
  }
}

.dailyVolumeChart {
  height: 300px;
}

.dailyVolumeChartContainer {
  min-height: 330px;
  position: relative;

  .filterRow {
    display: flex;

    .dateRangeSelectorContainer {
      margin-right: 10px;
    }

    .dropdownInputWithSearch {
      // remove previous margins
      margin-right: 0px;
      margin-left: auto;

      &__menuitemContainer {
        height: 220px;
      }
      .dropdownInputWithSearch__button {
        border: 1px solid #ddd;
        border-radius: 3px;
        padding: 3px 15px 3px 5px;
        text-transform: capitalize;
        text-align: left;
        width: 75px;

        & > span {
          position: absolute;
          right: 5px;
          top: 50%;
          transform: translate(0, -50%);
        }
      }

      .dropdown-menu-right {
        right: -74px;
      }
    }
  }
}

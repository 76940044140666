.systemBalance {
  margin-top: 15px;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  min-width: 300px;

  & &__divider {
    margin: 0 15px;
  }

  .section {
    margin-right: 5px;
  }
}

.systemBalanceHeader {
  display: flex;
  flex-direction: row;

  & &__title {
    flex-grow: 2;
    font-weight: bold;
  }

  & &__estimatedValue {
    text-align: right;
  }

  & &__toggle .btn-group button {
    box-shadow: none;
    border: 1px solid #ddd;
    outline: none;
    font-size: 10px;
    padding: 5px 10px;
  }

  &__caption {
    font-weight: bold;
  }
}

.sampleComparisonTable {
  height: 650px;

  &__measurementCell {
    width: 125px;
    overflow-x: auto;

    .linkableText {
      margin-right: 8px;
    }
  }
}

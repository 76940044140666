.member .member__createUserRow {
  height: 40px;
}

.members .members__button {
  background: #0ca5a5;
  box-shadow: none;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  padding: 8px 13px;
}

.members .members__button:hover {
  text-decoration: none;
}

.memberTable .customTable .ReactVirtualized__Table__row {
  border: none;
}

.members__filterRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
}

.membersActionCell {
  color: var(--link-colour);
  cursor: pointer;
  font-size: 18px;
}

.membersNameCell__name {
  color: #616161;
  font-weight: bold;
  text-transform: capitalize;
  margin-bottom: -5px;
}

@import "constants/index.js";

.workflowsViewTable {
  &__workflowCell {
    white-space: pre-wrap;
    padding-right: 10px;
  }

  &__testsCell {
    height: 45px;
    overflow: auto;
  }

  &__row {
    &--grey {
      color: $disabledFontColour !important;
    }

    &--overflow {
      overflow: visible !important;
    }
  }

  .ReactVirtualized__Grid__innerScrollContainer {
    overflow: visible !important;
  }

  &__tooltip {
    .tooltip-inner {
      max-width: 250px;
    }

    ul {
      padding-left: 15px;
    }

    li {
      text-align: left;
      line-height: 1.4;
      margin-bottom: 5px;
    }

    .title,
    .value {
      overflow: hidden;
      word-break: break-all;
    }

    .title {
      font-weight: bold;
    }
  }

  & &__dismissOption {
    a {
      &,
      &:hover,
      &:active {
        background-color: $statusErrorColour !important;
        color: white !important;
      }
    }
  }

  .actionColumn {
    .dropdown-menu {
      padding: 0;
      min-width: 120px;

      li:last-child {
        a {
          border-bottom-left-radius: 3px;
          border-bottom-right-radius: 3px;
        }
      }
    }

    .btn {
      box-shadow: none;
      padding: 3px 5px;
      color: $linkColour;
      text-transform: none;
      outline: none;

      &:hover,
      &:active {
        cursor: pointer;
      }

      &:active {
        background-color: $menuHighlightColour;
      }
    }
  }
}

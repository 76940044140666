.choice-section-container {
  max-width: 100%;

  &.taskComplete {
    pointer-events: none;
  }
}

.choice-section-title {
  color: var(--colour-text-800);
  font-size: 16px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: 0.16px;
}

.choice-section-description {
  color: var(--colour-text-600);
  font-size: 14px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: 0.14px;
}

.choice-card-container {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.choice-card {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  padding: 16px;
  gap: 10px;
  border-radius: 8px;
  border: 1px solid var(--colour-surface-200);
  cursor: pointer;

  &.flash:focus-visible {
    outline: 3px solid var(--colour-primary-100);
  }

  &.isSelected {
    border: 1px solid #447c9c;

    &.taskComplete {
      border: 1px solid var(--colour-surface-300);
    }

    &.flash:focus-visible {
      border: none;
    }
  }
}

.choice-title {
  color: var(--colour-text-600);
  font-size: 16px;
  font-weight: 600;
  line-height: 20.8px;
  letter-spacing: 0.16px;
  margin-bottom: 0;
  max-width: 336px;
  word-wrap: break-word;

  &.isSelected:not(.taskComplete) {
    color: var(--colour-text-800);
  }
}

.choice-description {
  color: var(--colour-text-400);
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: 0.14px;
  margin-bottom: 0;
  max-width: 336px;
  word-wrap: break-word;

  &.isSelected {
    color: var(--colour-text-600);
  }
}

.check-circle-icon {
  color: #447c9c;

  &.taskComplete {
    color: var(--colour-surface-300);
  }
}

.flash-enabled:focus-visible {
  transition: outline 250ms ease-in-out;
}

@keyframes flash {
  from {
    outline: initial;
  }
  to {
    outline: var(--colour-primary-100);
  }
}

.addPicklistItemButton {
  margin-bottom: 16px;
}

.pickListOptionsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;
}

.comparativeConditionContainer {
  display: flex;
  flex-direction: column;
  max-width: 100%;
}

.addConditionButton,
.addFilterButton {
  margin-top: 8px;
  margin-bottom: 16px;
}

.page-title {
  display: flex;
  align-items: center;
  gap: 12px;
  & > div:first-child {
    background-color: var(--colour-surface-200);
  }
}

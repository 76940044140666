.overviewAndMapSection {
  display: flex;
  gap: 10px;

  & > * {
    flex: 1;
  }
}

.overviewAndMapSection > * {
  flex: 1;
}

.overviewPanel {
  margin-bottom: 0px;
}

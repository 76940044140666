.formTitle {
  margin-bottom: 10px;
}

.button {
  margin-bottom: 10px;
}

.buttonRow {
  display: flex;
  justify-content: flex-end;
}

.deleteButton {
  margin-right: 10px;
}

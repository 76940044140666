.workflowNavigationBar {
  margin-bottom: 10px;
  display: flex;
  flex-direction: row;

  &__dateRange {
    display: flex;
    flex-direction: row;
    flex-grow: 2;

    .dateRangeSelectorContainer {
      color: #666;
      border: 1px solid #ddd;
      border-radius: 3px;
      color: #666;
      padding: 5px 10px;
      max-width: 250px;
    }

    .DayPicker {
      .DayPicker-Day {
        &--hoverRange {
          background-color: #f0f8ff;
        }

        &--hoverRangeStart {
          background-color: #4fc3f7 !important;
          border-radius: 15px 0 0 15px !important;
        }

        &--hoverRangeEnd {
          background-color: #4fc3f7 !important;
          border-radius: 0 15px 15px 0 !important;
        }

        &--outside {
          background-color: #ffffff !important;
        }
      }
    }
  }

  &__controls {
    box-shadow: none;
    height: 37px;

    > * {
      margin-left: 10px;
    }
  }

  &__checkbox {
    border: 1px solid #ddd;
    border-radius: 3px;
    box-shadow: none;
    padding: 6px 11px 4px 33px;
    text-transform: uppercase;
    margin-right: -5px;
  }

  &__csvExport,
  &__pdfExport {
    float: right;
    margin-right: 10px;
  }

  &__toggle {
    display: block;
    float: right;
    width: 500px;

    .btn-group {
      float: right;
    }

    button {
      box-shadow: none;
      border: 1px solid #ddd;
      float: right;
      height: 35px;
      padding: 2px 8px 2px 8px;
    }

    .active,
    .active:hover,
    .active:focus {
      background-color: #4dc7c8;
      color: #ffffff;
    }
  }
}

// REVIEW: this style relies on breaking modular SCSS and needs to be removed
.commonPanel.balanceSummary {
  min-height: 300px;
}

.balanceSummary {
  min-height: 300px;

  & &__hint {
    margin-top: 100px;
    text-align: center;
  }
}

.statusTitle {
  text-align: left;
  text-overflow: ellipsis;
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
}

.statusTitle__title {
  color: #9e9e9e;
  margin-bottom: -8px;
}

.statusTitle__text {
  font-size: 18px;
}

.workflowFilter__progressBarContainer {
  margin-top: 10px;
}

.roleTable__memberCell {
  display: inline-block;
  cursor: pointer;
}

.roleTable__memberCell .icon {
  display: inline-block;
  color: #546e7a;
}

.roleTable__nameCell {
  text-transform: capitalize;
}

.roleTable__memberList {
  max-height: 150px;
  overflow-y: auto;
}

.roleTable__memberList ul {
  padding: 0;
}

.roleTable__memberList .icon {
  color: #2196f3;
}

.roleTable__memberList ol {
  padding-left: 0;
  cursor: pointer;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.roleTable__memberActionCell {
  color: #2196f3;
  cursor: pointer;
  font-size: 18px;
}

.streamChooser .dropdownSearchField {
  border: 1px solid #ddd;
  box-shadow: none;
  height: 37px;
  padding-left: 5px;
}

/* Customize for Firefox only */
@-moz-document url-prefix() {
  .streamChooser .input-group .dropdownSearchField {
    border: 1px solid #ddd;
    border-left: 0;
    border-right: 0;
    box-shadow: none;
    height: 38px;
    padding-left: 5px;
  }
}

.streamChooser .tab-content {
  margin-top: 10px;
  min-height: 350px;
}

.streamChooser .inputForm {
  border: 1px solid #ddd;
  box-shadow: none;
  padding-left: 5px;
}

.streamChooser .dropdownSearchFieldCount {
  border: 1px solid #ddd;
  border-left: 0;
}

.streamChooser .ReactVirtualized__List {
  border: 1px solid #ddd;
  padding-left: 5px;
  outline: none;
}

.streamChooser .ReactVirtualized__List label {
  line-height: 15px;
}

.streamChooser .streamTableContainer {
  height: 300px;
}

.streamChooser .streamList_row_button {
  width: 100%;
}

.streamListModal .match-button {
  float: right;
  max-width: 200px;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.title {
  color: var(--colour-surface-600, #1b4254);
  font-size: 18px;
  font-weight: 600;
  line-height: 144%;
  letter-spacing: 0.18px;
  margin-top: 0;
  margin-bottom: 12px;
}

.option {
  &:hover {
    background-color: var(--colour-surface-100);
  }
}

.skeleton--fill {
  width: 100%;
  height: 200px;
}

.attachSubmissionTrigger {
  margin-bottom: 12px;
}

@import "constants/index.js";

.notesColumn {
  align-items: center;
  display: flex;
}

.notesColumn__hasNotes {
  .badge {
    background-color: $validereColour;
  }
}

.topContainer {
  display: flex;
  flex-direction: row;
  margin-bottom: 24px;

  & > div:nth-child(1) {
    flex: 1;
  }
}

.filterContainer {
  display: flex;
  gap: 8px;
  flex-direction: row;
  align-items: center;
  margin-bottom: 16px;
}

.versionPill {
  /* TODO: avoid generic element selectors in CSS */
  & > div:first-child {
    background-color: var(--colour-surface-200);
  }
}

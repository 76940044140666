.monthlyView__popupTaskBar {
  text-align: right;
  margin-right: 25px;
  height: 35px;
}

.monthlyView__popupTaskBar a {
  box-shadow: none;
  color: #797979;
  font-size: 18px;
  padding: 0 10px;
}

.nameInput {
  width: 244px;
}

.footerActions {
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 16px;

  & > div:first-child {
    flex: 1;
  }
}

.sectionHeader {
  font-size: 16px;
  font-weight: bold;
  margin-top: 16px;
  margin-bottom: 16px;
}

.conditionFieldsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

.removeConditionAction {
  margin: 0 0 16px 0;
}

.addConditionAction {
  margin: 16px 0 0 0;
}

.bold {
  font-weight: bold;
}

.label {
  overflow: hidden;
  color: var(--colour-text-800);
  margin: 0;
}

.modalActionsContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
}

.page.instrument {
  margin: 0px;
}

.page.instrument .breadcrumbs {
  margin-bottom: 0px;
  padding-left: 15px;
  padding-top: 15px;
  background-color: white;
}

.instrument .header-icon {
  font-size: 20px;
  float: left;
  width: 60px;
}

.instrument .panel {
  border: none;
  padding: 0px;
}

.instrument .infoPanel .header-subtitle {
  color: var(--colour-default);
}

.instrument .infoPanel {
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  min-height: 0;
}

.instrument .instrumentDetail {
  padding-left: 10px;
}

.instrument .spaceIcon {
  margin-right: 10px;
}

.instrument .instrumentIcon .circle {
  color: #1081e5;
}

.instrument .instrumentIcon .circle-text {
  color: #ffffff;
}

.instrument .title {
  padding-left: 10px;
}

.instrument .title .info {
  margin-top: -10px;
}

.instrument .title hr {
  margin-bottom: 0px;
  margin-top: -10px;
}

.instrument #status {
  padding: 7px;
  color: #807c7c;
  background-color: #ffffff;
  border-width: 20px;
  border: 1px solid var(--light-text-colour);
}

.instrument #status #circle-status {
  color: #4caf50;
}

.instrument .calibrationPanel {
  margin-top: 10px;
}

.instrument .calibrationPanel .calibrationTable td {
  border: 0;
}

.instrument .calibrationPanel .calibrationTable th {
  border: 0;
}

.instrument .instrumentSummary {
  margin-top: 33px;
}

.instrument .instrumentSummary .chartLabel {
  font-size: 15px;
  margin-top: 35px;
  text-align: center;
}

.instrument .instrumentSummary .panel {
  box-shadow: none;
  border: 1px solid #ddd;
  border-radius: 3px;
}

.instrument .instrumentSummary .panel-body {
  min-height: 326px;
}

.modal-header {
  padding-bottom: 0px;
}

.instrument .instrumentSummary .panel-default > .panel-heading {
  background-color: #ffffff;
  margin-bottom: -15px;
}

.instrument .instrumentSearch {
  margin-bottom: 15px;
}

.instrument .ReactTable {
  background: #ffffff;
  border: 0;
}

.instrument .instrumentDescription {
  height: 385px;
}

.instrument .instrumentDescription .table > tbody > tr > td {
  border: 0;
}

.inputbox .form-group {
  margin: 2% 2% 0 0;
}

.inputbox input.form-control {
  border: 1px solid #ddd;
  border-bottom: 0;
  height: 30px;
  text-align: center;
  font-size: 12px;
}

/* Instrumnet Summary: Instrument Calibration */

.instrumentDescription__table {
  max-width: 500px;
}

/* Instrument Summary: Instrument Description */
.instrumentDescription__table tbody > tr > td {
  border: 0;
}

.instrumentDescription__table .buttonLink {
  margin: -3px 0 0 5px;
  text-transform: capitalize;
}

.instrumentDescription__icon {
  margin-right: 5px;
  color: var(--validere-colour);
}

.instrumentDescription__headerWithSetting {
  background-color: #ffffff;
  color: #527986;
  font-size: 18px;
  margin-top: 0;
  margin-bottom: 0;
  padding: 7px 0;
}

/* Instrument Samples: Sample Report Modal */

.sampleReport {
  border: 1px solid #cac8c8;
  color: #ffffff;
  padding-bottom: 10px;
}

.sampleReport__table > tbody > tr > td {
  padding: 5px 0 0 10px !important;
  border: 0;
}

.sampleReport__detailInformation {
  font-size: 15px;
}

.sampleReport__icon {
  margin-right: 5px;
  color: var(--validere-colour);
}

.progressBarText {
  text-align: center;
}

.reportLink {
  color: var(--link-colour);
  cursor: pointer;
  border-right: 1px solid #dddddd;
}

.instrumentPanel .label {
  background: #607d8b;
}

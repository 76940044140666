@import "constants/index.js";

.createSampleTestList {
  border: 1px solid $borderColour;
  max-height: 150px;
  overflow-y: auto;

  &__listItem {
    border-bottom: 1px solid $borderColour;
  }

  &__listContent {
    margin-left: 5px;
  }
}

.container {
  display: grid;
  grid-template-columns: 6fr 4fr;
  grid-gap: 24px;
}

.tabsHeader {
  margin: 15px 0px;
}

.pageTitle {
  display: inline-flex;
}

.results {
  position: sticky;
  top: 0px;
  height: 50%;
  margin-bottom: 0px;
}

.editRecordTitleContainer {
  display: flex;
  justify-content: space-between;
}

.buttons {
  > * + * {
    margin-left: 10px;
  }
}

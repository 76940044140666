.dropdownInputWithSearch {
  margin: 0;
  border-radius: 3px;
}

.dropdownInputWithSearch .dropdown-menu {
  width: 350px;
  padding: 10px;
  cursor: pointer;
}

.dropdownInputWithSearch__button {
  box-shadow: none;
  border: 1px solid #ddd;
}

.dropdownInputWithSearch__dropdownSearch {
  padding: 0;
}

.dropdownInputWithSearch__dropdownSearch input.form-control {
  border-bottom: 1px solid #ddd;
  box-shadow: none;
  height: 30px;
  padding-left: 5px;
  font-size: 14px;
}

.dropdownInputWithSearch__menuitemContainer {
  height: 150px;
  width: 100%;
  overflow-y: auto;
  overflow-x: hidden;
}

.menuitem__selectAll {
  color: #555;
  padding: 5px 0 5px 5px;
  text-transform: capitalize;
}

.menuitem__selectAll:hover {
  background-color: #eeeeee;
}

.menuitem__checkContainer {
  color: var(--link-colour);
  display: inline-block;
  width: 20px;
}

.dropdownInputWithSearch__noResult {
  padding: 5px 0 0 5px;
}

.ReactVirtualized__Grid__innerScrollContainer .menuitem {
  padding: 5px 0 0 5px;
  text-transform: capitalize;
}

.ReactVirtualized__Grid__innerScrollContainer .menuitem:hover {
  background-color: #eeeeee;
}

.workflowDigestEmailSettings__timePicker {
  border: 1px solid #183e56;
  border-radius: 3px;
  margin: 0 10px;
  padding: 0px 8px;
  width: 95px;
}

.workflowDigestEmailSettings__disabledControl,
.workflowDigestEmailSettings__timePicker .rc-time-picker-input[disabled] {
  background: #eeeeee;
}

.workflowDigestEmailSettings__timePicker .rc-time-picker-input,
.workflowDigestEmailSettings__timePicker .rc-time-picker-input:focus {
  background: none;
  box-shadow: none;
  font-size: 13px;
  font-weight: bold;
  height: 24px;
  width: 65px;
}

.workflowDigestEmailSettings__checkbox {
  display: inline-block;
}

@import "constants/mixins";

.productionStreams {
  > * + * {
    margin-top: 10px;
  }
}

.dropdownSection {
  display: flex;
  margin-top: 14px;
  position: relative;
  z-index: var(--zindex-dropdown);

  > * + * {
    margin-left: 24px;
  }
}

.dropdownContainer {
  > * + * {
    margin-top: 6px;
  }
}

.dateSelectBottomSpacing {
  margin-bottom: 10px;
}

.dropdownTitle {
  @include fontStyles(body, 18);
}

.dropdown {
  width: 300px;
}

.tablePanel {
  min-height: 400px;
  margin-bottom: 10px;
}

.status {
  @include fontStyles(body, 12, true);
  display: inline-block;
  padding: 5px 16px;
  border-radius: 20px;
  background-color: var(--colour-warning-light);
}

.approved {
  background-color: var(--colour-success);
}

.error {
  color: var(--colour-white);
  background-color: var(--colour-error);
}

.labResults .lab-results-header {
  color: #527986;
  font-size: 18px;
  margin-top: 5px;
  margin-bottom: 20px;
}

.labResults .columns-select {
  z-index: 9;
}

.labResults .ReactTable {
  margin-top: 10px;
}

.labResults .rt-th > input {
  min-height: 38px;
}

.labResults .rt-thead.-filters {
  border-bottom: 1px solid #e4e4e4;
}

.labResults .rt-thead.-filters .rt-th:first-child {
  z-index: 8;
}

.labResults .rt-thead.-filters .rt-th {
  z-index: 7;
}

.labResults .rt-tbody .rt-tr-group {
  border-bottom: 1px solid #e4e4e4;
  height: 90px;
}

.labResults .-pagination .-btn {
  color: #fff;
  background-color: #46c0c1;
}

.labResults .-pagination .-btn:not([disabled]):hover {
  color: #fff;
  background-color: #46c0c1;
}

.labResults .rt-resizable-header {
  padding: 6px 24px !important;
  -webkit-box-shadow: none !important;
  box-shadow: none !important;
}

.labResults .rt-resizer:before {
  display: inline-block;
  position: absolute;
  right: 25px;
  top: 3px;
  height: 18px;
  width: 18px;
  color: transparent;
  content: ".";
  background-size: 18px 18px;
  background-repeat: no-repeat;
  opacity: 0.87;
}

.labResults .rt-resizable-header-content:after {
  position: absolute;
  right: 8px;
  top: 3px;
  height: 18px;
  width: 18px;
  z-index: 3;
  color: transparent;
  content: ".";
}

.labResults .rt-th.-sort-asc .rt-resizer:before {
  font-size: 17px;
  font-family: FontAwesome;
  content: "\f0d8";
  color: #2196f3;
}

.labResults .rt-th.-sort-desc .rt-resizer:before {
  font-size: 17px;
  font-family: FontAwesome;
  content: "\f0d7";
  color: #2196f3;
}

.labResults .labResults__actionRow .columns-select {
  display: inline-block;
  width: 300px;
}

.labResults .labResults__dateRangeContainer {
  border: 1px solid #ddd;
  border-radius: 3px;
  float: right;
  padding: 5px 10px;
  margin-right: 8px;
}

.labResults .labResults__dateRangeContainer .icon {
  color: var(--link-colour);
}

.labResults .labResults__pdfDownload {
  float: right;
}

.labResults .labResults__pdfDownload {
  border: 1px solid #ddd;
  box-shadow: none;
  color: #2196f3;
  padding: 0 5px 0 5px;
}

.labResults .labResults__checkBox {
  margin-right: 10px;
}

.labResults .tooltip-icon {
  margin-left: 0;
  color: #ff9800;
}

.labResults .labResultsTestsContainer {
  height: 65px;
  overflow-y: auto;
}

.labResults__downloadIcon {
  color: var(--link-colour);
}

.ReactTable .labResult__measurementValueCell button {
  color: #2196f3;
  padding: 0;
}

.popover__link {
  color: #2196f3;
  cursor: pointer;
  display: block;
  border-radius: 3px;
  padding: 5px;
}

.popover__link:hover {
  color: #2196f3;
  background-color: #f1f1f1;
  text-decoration: none;
}

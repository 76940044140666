.settingSection {
  margin-bottom: 25px;
}

.settingSection hr {
  margin-top: 5px;
  margin-bottom: 17px;
}

.settingSection__title {
  margin-top: 12px;
  text-transform: capitalize;
}

.settingSection__title .icon {
  margin-left: 5px;
  color: var(--link-colour);
}

.sectionSection__message {
  margin: -15px 0 15px 0;
}

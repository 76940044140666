.sectionContainer {
  margin-bottom: 16px;
}

.footerContainer {
  flex: 1;
  display: flex;
  width: 100%;
}

.footerActionsContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  gap: 16px;
  justify-content: flex-end;
  align-items: center;
}

.errorCount {
  color: red;
  margin: 0;
}

.banner {
  position: sticky;
  margin-top: 16px;
  top: 36px;
  z-index: var(--zindex-sticky);
}

.pageWithBanner {
  [role="main"] {
    padding-top: 0;
  }
}

.pageWithoutBanner {
  [role="main"] {
    padding-top: 24px;
  }
}

.bannerOffset {
  margin-top: 16px;
}

.summaryContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 8px 20px;
}

.summaryContainerItem {
  width: 250px;
}

.actionsRow {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.quick-container {
  display: flex;
  align-items: center;
}

.trigger-container {
  position: relative;
}

.filters-count {
  position: absolute;
  top: -4px;
  right: -4px;
  background-color: var(--colour-surface-600);
  color: var(--colour-surface-100);
  font-weight: 600;
  padding: 2px 6px;
  border-radius: 20px;
  font-size: 1.2rem;
  line-height: 1.3;
}

.saved-filters-preview {
  padding: 16px;
  border-radius: 6px;
  background-color: var(--colour-surface-50);
}

.saved-filters-preview-title {
  margin-bottom: 8px;
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--colour-text-400);
}

.saved-filters-preview-content {
  display: flex;
  gap: 8px;
  flex-wrap: wrap;
}

.applied-saved-filter-indicator {
  background-color: var(--colour-default-light);
  color: var(--colour-default);
  padding: 8px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
}

.applied-saved-filter-text {
  color: var(--colour-default);
  margin: 0;
}

.card {
  border-radius: 6px;
  padding: 16px;

  &.inset {
    border: 1px solid var(--colour-surface-200, #e6eaec);
    background: var(--colour-surface-50, #f9fafb);
  }

  .actions {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    gap: 8px;
  }
}

.testAnalysis__alertList {
  max-height: 150px;
  overflow-y: auto;
  margin-bottom: 15px;

  .alert {
    border-radius: 3px;
    margin: 5px 0;
    padding: 10px 10px;

    ul {
      padding: 0px 25px;
    }
  }

  .warning {
    color: #856404;
    background-color: #fff3cd;
    border-color: #ffeeba;
  }

  .error {
    color: #842029;
    background-color: #f8d7da;
    border-color: #f5c2c7;
  }
}

@import "constants/index.js";

.recombinedReportModal {
  width: 1000px;
  margin: 30px auto;

  .recombinedReportModal {
    &__table {
      height: 600px;
      width: 800px;

      .datatable {
        border: 1px solid $borderColour;
        border-radius: 3px;
        padding: 5px;

        &__leftSideGridContainer {
          margin: 5px;
        }

        &__row {
          text-transform: capitalize;
        }
      }
    }
  }
}

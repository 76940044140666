.contentContainer {
  display: grid;
  grid-template-columns: auto;
  gap: 16px;
}

.resourceStatusCountContainer,
.titleContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
}

.divider {
  width: 100%;
  margin: 0;
  margin-top: -8px;
  border: 1px solid #e6eaec;
}

.title {
  color: #1b4254;
  font-size: 20px;
  line-height: 26px;
  letter-spacing: 0.1px;
}

.subtitle {
  color: #1b4254;
  font-family: "IBM Plex Sans";
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 18.2px; /* 113.75% */
  letter-spacing: 0.16px;
}

.keyValueListContainer {
  border-radius: 6px;
  padding: 0px 12px;
  background-color: var(--colour-surface-75);
  border-color: #e6eaec;
}

.buttonContainer {
  display: flex;
  gap: 8px;
}

.drawerContainer {
  display: flex;
  flex-direction: column;
  height: 100vh;
}

.accordionTitle {
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: space-between;
}

.associatedResourcesOuterList {
  display: flex;
  border-radius: 6px;
  padding: 8px 16px;
  flex-direction: column;
  flex-grow: 1;
  border: 1px solid #e6eaec;
  list-style-type: none;
}

.associatedResourcesInnerListContainer {
  padding-right: 16px;
  padding-left: 16px;
}

.associatedResourcesInnerList,
.primaryAssociatedResourcesList {
  display: flex;
  border-radius: 6px;
  flex-direction: column;
  justify-content: center;
  padding: 0;
  border: 1px solid #e6eaec;
  list-style-type: none;
}

.selectedResourceItem {
  border-radius: 6px;
  border: 1px solid #1b4254;
}

.resourceItem {
  border-bottom: 1px solid #e6eaec;
}

.resourceTypeLabel {
  padding: 8px 16px;
  margin: 0;
  color: #1b4254;
}

.resourceLabel {
  padding: 8px 16px;
  margin: 0;
  color: var(--ifm-color-primary-dark);
  font-weight: 700;
}

.resourceFailedLabel {
  padding: 8px 16px;
  margin: 0;
  display: flex;
  align-items: center;
  color: #ff0000;
  font-weight: 700;

  > :last-child {
    margin-right: 5px;
  }
}

.resourceSubmittedLabel {
  padding: 8px 16px;
  margin: 0;
  display: flex;
  align-items: center;
  color: #e37006;
  font-weight: 700;

  > :last-child {
    margin-right: 5px;
  }
}

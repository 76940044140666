.container {
  display: grid;
  gap: 10px;
}

.detailValueWithContext {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

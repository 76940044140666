@import "constants/index.js";

.recordOfQuality {
  min-height: 600px;

  &__status {
    color: $statusPendingColour;
    border: 1px solid $statusPendingColour;
    border-radius: 3px;
    display: inline-block;
    padding: 0 5px;
    font-size: 9px;

    &.approved {
      border-color: $statusCompletedColour;
      color: $statusCompletedColour;
    }

    &.error {
      border-color: $statusErrorColour;
      color: $statusErrorColour;
    }
  }

  &__errorMessage {
    text-align: center;
    margin-top: 35px;
  }

  // REVIEW: this style relies on breaking modular SCSS and needs to be removed
  .resizePanels .commonPanel {
    width: 100%;
    overflow-x: auto;
    overflow-y: auto;
  }
}

.userSettings {
  display: flex;
}

.userSettings__navs {
  background: #ffffff;
  border-right: 1px solid #ddd;
  flex: 0 0 200px;
  margin-right: 15px;
  position: fixed;
  width: 200px;
  height: 100%;
}

.userSettings__navsHeader {
  margin: 15px 0 10px 15px;
  font-weight: bold;
}

.userSettings__navs .nav > li > a {
  padding: 2px 15px;
  color: var(--colour-text-800);
}

.userSettings__navs .nav .active {
  font-weight: bold;
  border-left: 4px solid #0ca5a5;
}

.userSettings__content {
  flex: 1 1;
  margin-right: 15px;
  margin-left: 215px;
}

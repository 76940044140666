.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.stepperFooterContainer {
  display: flex;
  flex-direction: row;
  gap: 16px;
}

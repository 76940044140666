.recordOfVolumeContainer {
  // REVIEW: this style relies on breaking modular SCSS and needs to be removed
  &__tabView.commonPanel {
    margin: 15px 0 0 0;
    border: none;
    padding: 0;
    box-shadow: none;
  }
}

.page {
  .recordOfVolumePage__tab {
    margin: 0;
  }
}

#adjustment-factor-tooltip ul {
  padding: 0;
  text-align: left;
}

.recordOfVolumeTable__divider {
  margin: 0 5px;
}

.recordOfVolume__adjustmentOrderTooltip {
  .tooltip-inner {
    max-width: 300px;
    background-color: rgba(0, 0, 0, 0.8);
  }
}

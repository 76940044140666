.analyzeTable {
  &__circle {
    height: 15px;
    width: 15px;
    border-radius: 50%;
    display: inline-block;
    font-size: 9px;
    color: #fff;
    text-align: center;
    vertical-align: text-bottom;
    font-weight: bold;
  }

  &__inlineRow {
    cursor: default !important;
    background: transparent !important;
  }

  &__tooltipContent {
    max-height: 250px;
    overflow-y: auto;
  }
}

.totalAccumulation {
  display: flex;
  flex-direction: row;
  border: 1px solid var(--borderColour);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 25px;
  position: sticky;
  top: 0;
  background-color: var(--colour-white);
  z-index: var(--zindex-layer-first);

  &__icon {
    .error {
      color: var(--colour-error-500);
    }

    .success {
      color: var(--colour-primary-500);
    }

    font-size: 24px;
    margin-right: 15px;
  }

  &__title {
    font-weight: bold;
  }

  &__subtitle {
    margin-bottom: 10px;
  }

  &__value {
    font-weight: bold;
  }
}

.balanceSourceTable {
  height: 500px;
  margin-bottom: 15px;

  .addButton {
    padding: 0px 10px;
  }
}

.balancesForm {
  margin-left: 10px;

  &__title {
    margin-bottom: 15px;
    text-transform: capitalize;
  }
}

.sourceTable__name {
  width: 90%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.sourceInput__actionRow {
  margin-bottom: 15px;
  display: flex;
  flex-direction: row;

  .buttonContainer {
    display: flex;
    flex-direction: row;
    flex-grow: 1;

    > * + * {
      margin-left: 10px;
    }
  }
}

.roleNotificationListRow__title {
  display: inline-block;
  text-transform: capitalize;
}

.roleNotificationSettingList {
  max-width: 800px;
}

.roleNotificationSettingList .customList__row {
  padding: 10px;
}

.roleNotificationListRow__link {
  color: #ffffff;
  background: #08a5a5;
  border: 1px solid #ddd;
  border-radius: 5px;
  box-shadow: none;
  margin-top: -5px;
  padding: 5px 10px;
}

.roleNotificationListRow__link:hover {
  color: #ffffff;
  text-decoration: none;
}

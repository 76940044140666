.associatedTestsCell {
  overflow-y: auto;
  height: 100%;
  width: 100%;

  &__row {
    display: flex;
    justify-content: space-between;
  }

  &__label {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  &__value {
    margin-left: 10px;
    white-space: nowrap;
  }

  &__name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 10px;
  }

  &__nameLabel {
    white-space: nowrap;
  }

  &__popover {
    display: flex;
    flex-direction: column;
    width: 350px;
    max-width: 350px;

    .popover-content {
      max-height: 250px;
      display: flex;
      flex-direction: column;
      padding-right: 5px; // remove 9px so padding consistent
    }

    &__title {
      margin-bottom: 10px;
    }

    &__content {
      flex: 1 1;
      overflow-y: auto;
      padding-right: 9px; // add 9px, separate scrollbar from content
    }
  }
}

// calculated measurement equation styles
.question-heading {
  font-weight: bold;
}

.equation-empty-state {
  padding: 0;
}

.equation-components-container {
  max-width: 100%;
}

.equation-component-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  margin-bottom: 16px;
  align-items: center;

  .equation-component-dropdown {
    min-width: 0;
    margin-bottom: 0;
    :global(.Dropdown-module__select) {
      min-width: 0;
    }
  }
}

.add-equation-component-dropdown-container {
  margin-bottom: 16px;
  button {
    width: 100%;
  }
}

.add-equation-component-dropdown {
  min-width: 432px;
}

.equation-preview-container {
  display: flex;
  padding: 4px 8px;
  margin-bottom: 16px;
  flex-direction: column;
  border-radius: 6px;
  background: var(--colour-surface-50);
  overflow-wrap: break-word;

  p {
    margin-bottom: 0;
  }

  .heading {
    color: var(--colour-text-600);
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
  }

  .equation {
    color: var(--colour-text-800);
    font-size: 14px;
    font-weight: 600;
    line-height: 18.2px;
  }
}

// measurement configuration styles
.switch-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;

  label {
    margin-bottom: 0px;
  }
}

.tooltip {
  height: 16px;
}

.measurement-config-dropdown-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
}

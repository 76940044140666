.container {
  display: grid;
  grid-template-columns: auto;
  gap: 12px;

  & > *:first-child {
    order: 2;
  }

  & > *:last-child {
    order: 1;
  }

  @media (min-width: 1280px) {
    grid-template-columns: 2fr 1fr;

    & > *:first-child {
      order: 1;
    }

    & > *:last-child {
      order: 2;
    }
  }
}

.container {
  display: flex;
  flex-direction: column;
  gap: 32px;

  @media only screen and (min-width: 1024px) {
    flex-direction: row;
  }
}

.contentContainer {
  flex: 1 0 auto;
}

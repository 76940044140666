.checkbox-container {
  display: flex;
  align-items: center;
  gap: 8px;
}

.measurement-config-dropdown-container {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-top: 16px;
  margin-bottom: -16px;
}

.checkbox {
  margin-top: 16px;
}

.switch-container {
  margin-bottom: 16px;
}

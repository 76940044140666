// REVIEW: this style relies on breaking modular SCSS and needs to be removed
.commonPanel.sourceSummary {
  min-height: 150px;
}

.sourceSummary {
  & &__header {
    text-transform: capitalize;
  }

  & &__hint {
    text-align: center;
  }

  .sourceSummaryTableContainer {
    min-height: 300px;
  }
}

.inventoryChart {
  position: relative;
  height: 450px;

  > * + * {
    margin-top: 20px;
  }

  &__container {
    height: 400px;
  }
}

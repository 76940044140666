.customTable .tooltip-icon {
  margin: 0px;
  margin-right: 5px;
}

.tableContainer {
  margin-bottom: 15px;
}

.inlineAnalyseSelectorRow {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
}

.inlineAnalyseSelectorRow
  .multipleDropdownInputWithSearch
  #input-dropdown-addon {
  border-radius: 3px;
  padding: 0 5px;
}

.inlineAnalyseSelectorRow .input-group-btn {
  width: 100%;
}

.inlineAnalyseSelectorRow .dropdownInputWithSearch {
  display: inline-block;
}

.inlineAnalyseSelectorRow .dropdownInputWithSearch .dropdown {
  display: inline-block;
}

.inlineAnalyseSelectorRow .dropdownInputWithSearch .dropdown button {
  padding: 0 5px 0 5px;
  border: 1px solid #ddd;
  border-radius: 3px;
  display: inline-block;
  text-transform: capitalize;
}

.inlineAnalyseSelectorRow .divider {
  border-right: 1px solid #ddd;
  display: inline-block;
  height: 19px;
  vertical-align: middle;
}

.inlineAnalyseSelectorRow
  .dateRangeSelectorContainer
  .DayPickerInput
  input::placeholder {
  color: var(--link-colour);
}

.inlineAnalyseSelector__noData {
  text-align: center;
  height: 150px;
  padding-top: 75px;
}

.inlineAnalyse {
  min-height: 400px;
}

.volumeSummary {
  > * + * {
    margin-top: 20px;
  }

  &__content {
    display: flex;
  }

  &__subtitle {
    margin-bottom: 20px;
  }

  &__filledVolume {
    flex: 1 1;
  }

  &__chart {
    height: 450px;
    flex: 6 1;
    padding-left: 20px;
    position: relative;

    &Header {
      display: flex;
      justify-content: space-between;

      .dateRangeSelectorContainer {
        border: 1px solid #ddd;
        border-radius: 3px;
        color: #666;
        height: 30px;
        padding: 3px 10px;
      }
    }

    .componentLoader {
      margin-top: 0;
    }
  }
}

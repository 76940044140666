.alertMessageContainer {
  position: fixed;

  display: flex;
  flex-direction: column-reverse;

  z-index: var(--zindex-alert);
  bottom: 10px;
  width: 400px;
  left: 10px;
}

.alertMessageContainer__alertMessage {
  border-radius: 0px;
  margin-bottom: 0px;
  text-transform: capitalize;
  font-weight: bold;
}

.alertMessageContainer__alertMessage h5 {
  color: white;
}

.alertMessageContainer__icons {
  display: inline-block;
}

.alertMessageContainer__icons .icon {
  font-size: 23px;
  margin-right: 10px;
}

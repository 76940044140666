.container {
  display: inline-flex;
  align-items: center;
}

.content-container {
  padding: 0;
}

.preview-container {
  padding: 12px;
}

.map-container {
  display: grid;
  grid-template-rows: minmax(30px, max-content) minmax(150px, 2fr);
  grid-template-columns: minmax(150px, auto);
}

.map-description-container {
  background: var(--colour-surface-0);
  padding: 8px;
  border-bottom: 1px solid var(--colour-surface-100);
  box-shadow: 0px 5px 20px rgba(49, 142, 228, 0.1);
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
}

.map-description {
  margin: 0;
  line-height: 1.2;
  font-weight: 400;
  font-size: 14px;
  color: var(--colour-text-600, #4d6670);
}

.map-coordinate-details {
  display: flex;
  gap: 8px;
  padding: 8px;
  background: var(--colour-surface-50, #f9fafb);
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;

  .map-coordinates {
    flex: 1 1 auto;
    margin: 0;
    display: inline-flex;
    align-items: center;
    color: var(--colour-text-400, #82939a);
    overflow-wrap: anywhere;
  }
}

.preview-trigger {
  &[data-state="open"] {
    background: none;

    & [data-content] {
      text-decoration: underline;
      text-underline-offset: 4px;
    }
  }
}

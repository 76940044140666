.testManagement {
  min-width: 925px;

  .textWrapColumn {
    white-space: normal;
  }
}

.errorButton {
  margin-right: 10px;
}

@import "constants/index.js";

.submitAnalysisModal {
  &__applicablePeriodDropdown {
    width: calc(50% - 5px);
    margin-bottom: 10px;
  }

  .dateRangeSelectorContainer {
    border: 1px solid #ddd;
    border-radius: 3px;
    color: $textColour;
    height: 30px;
    padding: 3px 10px;

    button {
      border: none !important;
    }
  }
}

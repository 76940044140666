.activityCell__icon {
  font-size: 20px;
  display: inline-block;
  margin: 0 10px 0 0;
}

.activityTable__link {
  text-transform: capitalize;
  color: #2196f3;
  display: block;
  border-radius: 3px;
  padding: 5px;
}

.sourceSelectionModal {
  &__title {
    text-transform: capitalize;
  }

  &__footer {
    > * + * {
      margin-left: 10px;
    }
  }
}

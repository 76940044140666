.chainOfCustodyDetailModal__table .title {
  width: 100px;
}

.chainOfCustodyDetailModal .chainOfCustodyDetailModal__input {
  border: 1px solid #ddd;
  font-size: 14px;
  padding-left: 5px;
}

.chainOfCustodyDetailModal .chainOfCustodyDetailModal__input[disabled] {
  border: 1px solid #eee;
  background-color: #eee;
}

.chainOfCustodyDetailModal .samplesRow__content {
  cursor: default;
}

.chainOfCustodyDetailModal__sampleList {
  border: 1px solid #ddd;
  height: 300px;
  overflow-y: auto;
}

.chainOfCustodyDetailModal__sampleListItem {
  border-bottom: 1px solid #ddd;
}

.chainOfCustodyDetailModal__sampleListContents {
  margin-left: 5px;
}

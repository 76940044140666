.filterContainer {
  display: flex;
  align-items: start;
  gap: 8px;
  margin-bottom: 16px;
}

.commentsButton {
  padding: 8px;
  height: 36px;

  &--active {
    border: 1px solid var(--colour-surface-200);
    background-color: var(--colour-surface-75);
  }
}

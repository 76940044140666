.form {
  height: 100%;
}

.detailsStepContainer {
  max-width: 244px;
}

.footerContainer {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

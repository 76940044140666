@import "constants/mixins";

.testDetailModal {
  width: 855px;
  min-height: 600px;

  &__footer {
    > * + * {
      margin-left: 10px;
    }
  }

  .nav-tabs {
    margin-bottom: 15px;
    @include fontStyles(body, 16);
  }

  &__errorMessage {
    text-align: center;
    margin-top: 20px;
  }
}

.tabHeader {
  margin-bottom: 15px;
  @include fontStyles(body, 16);
}

.pillIndicator {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  margin: 0px 4px 0px 0px;
  display: inline-block;
  flex: none;
  order: 0;
  flex-grow: 0;
  background: var(--colour-text-200);

  &.info {
    background: var(--colour-default);
  }
  &.success {
    background-color: var(--colour-success);
  }
  &.error {
    background-color: var(--colour-error);
  }
  &.primary {
    background-color: var(--colour-primary-500);
  }
}

.keyValueListContainer {
  border-radius: 6px;
  padding: 0px 12px;
  background-color: var(--colour-surface-75);
  border-color: #e6eaec;
}

@import "constants/mixins";

.title {
  @include fontStyles(body, 16, true, 600);
  margin-bottom: 16px;
  display: inline-block;
}
.checkbox-container {
  margin: -10px 0 20px 0;
  padding-left: 16px;
}

.date-range-fieldset .date-range-checkbox {
  margin-bottom: 6px;
}

@import "constants/index.js";

.assignTestModal {
  width: 80%;
  min-width: 1000px;
  margin: 30px auto;

  .errorMessage {
    color: var(--statusErrorColour);
  }

  .textWrapColumn {
    white-space: normal;
  }

  .alert-info {
    margin-bottom: 10px;
  }

  &__table {
    margin-top: 22px;
    height: 450px;

    &--test {
      height: 325px;
      margin-bottom: 40px;
    }
  }
}

.container {
  display: block;
}

.form-container {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
}

.streamAlertsView {
  &__tooltip {
    text-align: left;
  }
  &__tooltipHeader {
    font-size: medium;
    font-weight: 500;
    text-align: left;
  }
  &__alertUnderline {
    text-decoration: underline;
  }
}

.streamAlertsCreate {
  &__formSectionTitle {
    margin-bottom: 10px;
  }
  &__submitButton {
    margin-bottom: 45px;
  }
  &__tableForm {
    height: 250px;
  }
  &__deleteButton {
    margin-right: 10px;
  }
}

.createChainOfCustody {
  min-width: 800px;

  .input-group .form-control {
    z-index: 0;
  }

  & &__actionRow {
    display: flex;
    flex-direction: row;
    margin-bottom: 15px;

    .buttonContainer {
      flex-grow: 1;

      button {
        margin-right: 10px;
      }
    }

    .iconContainer {
      font-size: 24px;
      margin-right: 15px;

      .error {
        color: var(--colour-error-500);
      }

      .success {
        color: var(--colour-primary-500);
      }

      .disabled {
        color: var(--colour-surface-200);
      }
    }
  }

  & &__footer {
    margin-top: 15px;
  }
}

.createChainOfCustody__title {
  font-size: 14px;
  margin-bottom: 20px;
}

.createChainOfCustodyModal .createChainOfCustodyModal__input {
  border: 1px solid #ddd;
  padding-left: 5px;
}

.createChainOfCustodyModal__dropdown {
  box-shadow: none;
  border: 1px solid #ddd;
}

.createChainOfCustodyModal__dropdown:focus {
  box-shadow: none;
  border: 1px solid #ddd;
}

.createChainOfCustody .form-group {
  margin-bottom: 50px;
}

.inventoryDetail {
  min-width: 900px;
  min-height: 100vh;
  margin: 0px;

  > .breadcrumbs {
    margin-bottom: 0px;
    padding-left: 15px;
    padding-top: 15px;
    background-color: white;
  }

  &__summarySection {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 4px;

    > * {
      flex: 1 1;
    }
  }

  &__tabContent {
    padding: 15px 15px 0px;
  }

  &__tabHeader {
    background-color: white;
    padding-left: 15px;
    padding-right: 15px;
  }
}

@import "constants/mixins";

.formContainer {
  display: grid;
  grid-template-columns: auto;
  gap: 32px;

  @media (min-width: 1024px) {
    grid-template-columns: minmax(16px, 320px) auto;
  }
}

.configContainer {
  display: flex;
  flex-direction: column;
}

.calculatorLegend {
  @include fontStyles(body, 16, true);
  color: var(--colour-text-800);
  border: none;
  margin-bottom: 8px;
}

.previewContainer {
  border-radius: 6px;
  border: 1px solid var(--colour-surface-100);
}

.formulaPreview {
  padding: 32px;
}

.skeletonLoader {
  display: flex;
  flex-direction: column;
  gap: 16px;

  &:not(:last-child) {
    margin-bottom: 16px;
  }
}

.skeletonFilter {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.volumeEntryModal {
  width: 800px;

  &__content {
    padding: 16px 32px 0 32px;
  }

  &__tabs {
    span {
      font-size: 13px;
      text-transform: uppercase;
      vertical-align: middle;
      letter-spacing: 1px;
    }
  }

  &__form {
    margin-top: 10px;
    padding: 15px 0;
  }

  &__adjustmentInput {
    margin-bottom: 10px;
  }

  &__adjustmentOrderList {
    margin-bottom: 22px;
  }

  &__adjustmentOrderHint {
    margin-bottom: 15px;
  }

  .form__unit {
    white-space: nowrap;
  }

  .formRows.selectInput .react-select__control {
    text-transform: none;
  }

  .actionRow {
    margin-top: 15px;
  }
}

.container {
  display: grid;
  grid-template-columns: max-content 3fr;
  gap: 24px;
}

.subsection {
  border: 1px solid var(--colour-surface-100);
  border-radius: 8px;
  padding: 16px;
}

.subsectionHeader {
  font-weight: 600;
  font-size: 16px;
  color: var(--colour-text-800);
}

.streamsHeader {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

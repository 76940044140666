.recordOfQualitySelection {
  border-right: 1px solid #ddd;
  position: relative;
  overflow-y: auto;
  height: 100%;
  padding-right: 15px;

  .sidePanelSection__subHeader {
    margin-bottom: 15px;
  }

  &__filterRow {
    display: flex;
    align-items: center;
    height: 30px;

    > * + * {
      margin-left: 10px;
    }

    & &__measurement,
    & &__method {
      display: flex;
      align-items: center;
      max-width: 100%;

      // use container to space direct children only
      > * + * {
        margin-left: 10px;
      }

      .dropdownInputWithSearch {
        // remove previous margins
        margin-right: 0px;

        &__menuitemContainer {
          height: 220px;
        }
        .dropdownInputWithSearch__button {
          padding: 3px 15px 3px 5px;
          border: 1px solid #ddd;
          border-radius: 3px;
          text-transform: capitalize;
          min-width: 125px;
          max-width: 250px;
          // below needed to set a max width and still have caret visible
          text-align: left;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;

          & > span {
            position: absolute;
            right: 5px;
            top: 50%;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }
}

@import "constants/index.js";

.instrument__navButtons li {
  & > a {
    & {
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      color: var(--colour-text-600);
    }

    &:hover,
    &:focus {
      color: var(--colour-text-800);
    }
  }

  &.active > a {
    &,
    &:hover,
    &:focus {
      border-radius: 3px;
      padding: 5px 10px 5px 10px;
      background-color: var(--colour-primary-700);
      color: var(--colour-white);
    }
  }

  .badge {
    background-color: $validereColour;
    margin-bottom: 1px;
  }

  & > .active > a > .badge {
    background-color: var(--colour-white);
    color: $validereColour;
  }

  & > .disabled > a {
    color: $lightTextColour;
  }
}

.resultDifference {
  display: flex;
  flex-wrap: nowrap;

  &__value {
    width: 95%;
    text-align: right;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    flex-grow: 1;
  }

  &__unit {
    margin-left: 2px;
    text-align: right;
  }
}

.container {
  display: block;
  margin-bottom: 16px;
}

.view-filters-container {
  flex: 1;
}

.view-filters-form {
  gap: 8px;
}

@import "constants/index.js";

.accountingPeriodSelectionPanel {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 650px;

  // REVIEW: this style relies on breaking modular SCSS and needs to be removed
  // reduce padding-top by 10, increase margin-top of children by 10
  // if children wrap, there'll be margin between them
  &.commonPanel {
    padding-top: 5px;
    min-height: auto;
  }

  & &__filterContainers {
    align-items: center;
    display: flex;
    flex-direction: row;
    flex-grow: 2;
    margin-top: 10px;
    gap: 10px;

    .title {
      font-weight: bold;
    }
  }

  & &__actionContainers {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-top: 10px;

    .dropdown {
      margin-right: 10px;
    }

    .approveBy {
      padding: 7px;
      font-style: italic;
    }

    .generateReportOption {
      a {
        &,
        &:hover,
        &:active {
          background-color: $linkColour !important;
          color: white !important;
        }
      }

      .icon {
        color: white;
      }
    }

    .count {
      display: inline-block;
      background: #fff;
      border-radius: 15px;
      height: 20px;
      margin-right: 10px;
      color: #15aab1;
      vertical-align: middle;
      font-weight: bold;
      font-size: 12px;
      line-height: 1.7;
      padding: 0 10px;
      pointer-events: none;
    }
  }
}

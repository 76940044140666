.role__createRoleButton {
  background: #08a5a5;
  border-radius: 5px;
  color: #ffffff;
  display: inline-block;
  padding: 8px 13px;
  text-transform: capitalize;
}

.role__createRoleButton:hover {
  text-decoration: none;
  background: #08a5a5;
  color: #ffffff;
}

.manageRoleContainer {
  height: 500px;
  max-width: 350px;
}

.manageRole__Row {
  text-transform: capitalize;
}

.manageRoleContainer .btn {
  border: 1px solid #ddd;
  box-shadow: none;
  float: right;
}

.manageRoleContainer .btn:focus {
  outline: none;
}

@import "constants/index.js";

.recordOfQualityResultPricing {
  height: 100%;
  padding-right: 20px;
  border-right: 1px solid $borderColour;

  &__title {
    margin-bottom: 10px;
  }

  &__value {
    font-size: 14px;
    font-weight: bold;
  }

  &__label {
    line-height: 1;
    font-size: 13px;
    color: $lightTextColour;
  }

  .resultDifference__value {
    text-align: left;
  }
}
